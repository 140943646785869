export const rocker = [
    'Afwikkelbalk',
    'Versnelde afwikkeling',
    'Vertraagde afwikkeling',
    '2-fasen',
    'Polyfasische afwikkeling'
]

export const correction = [
    'Geheel proneren',
    'Geheel supineren',
    'Voor supineren',
    'Achter supineren',
    'Voor proneren',
    'Achter proneren',
    'Torsie',
    'Detorsie',
    '-'
]

export const correctionHeight = [
    '3mm',
    '4mm',
    '5mm'
]

export const solePad = [
    '3mm',
    '5mm'
]

export const stretch = [
    'Hallux mediaal',
    'Hallux dorsaal',
    'MTP V',
    'Geheel',
    'MTP regio',
    'Teenbox',
    'Haglundse exostose'
]

export const closureAdjust = [
    'Haken in plaats van ringen',
    'Ringen in plaats van haken'
]

export const soleStiffening = [
    '-',
    'Carbon dik',
    'Carbon dun'
]

export const insoles = [
    'Blauwe kuip',
    'Multicolor',
    'Sportzolen',
    'Birko kort'
]

export const ucbl = [
    'Harde poro',
    'Zachte poro'
]

export const forefootThickness = [
    '3 mm',
    '5 mm'
]

export const insoleMaterial = [
    'PPT',
    'Multiform'
]

export const insoleLength = [
    '¹⁄₂',
    '³⁄₄',
    '⁴⁄₄'
]

export const openPosition = { min: 0 }
export const lowerUpper = { min: 0 }
export const heel = { min: 0 }
export const ball = { min: 0 }
export const toe = { min: 0 }
export const lateral = { min: 0 }
export const medial = { min: 0 }
export const heelElevation = { min: 0 }
export const shoeSize = { min: 0, max: 100 }