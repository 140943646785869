// import UserService from './userService'

class DataProvider {
    /**
     * Constructor function
     */
    constructor () {
        this.folder = 'default'
    }

    /**
     * Get osbOutsole default values
     *
     * @returns a pallete
     */
    get osbOutsole () {
        let osbOutsole = null
        switch (this.folder) {
        default:
            osbOutsole = require('./data/default/osbOutsole')
            break
        }
        return osbOutsole
    }

    /**
     * Get osb default values
     *
     * @returns a pallete
     */
    get osb () {
        let osb = null
        switch (this.folder) {
        default:
            osb = require('./data/default/osb')
            break
        }
        return osb
    }

    /**
     * Get osaPattern default values
     *
     * @returns a pallete
     */
    get repair () {
        let repair = null
        switch (this.folder) {
        default:
            repair = require('./data/default/repair')
            break
        }
        return repair
    }

    /**
     *
     * @param {*} value
     */
    setFolder (value) {
        this.folder = value
    }

    /**
     *
     * @returns
     */
    getFolder () {
        return this.folder
    }
}

 
export default new DataProvider()
