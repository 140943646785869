export const initialState = {
    osbShoeData: {
        stock: null,
        brand: '',
        shoeType: '',
        soleType: '',
        last: '',
        sizeLeft: '',
        widthLeft: '',
        sizeRight: '',
        widthRight: '',
        color: '',
        productNr: '',
        note: ''
    },
    osbShoeAccordion: {
        accordion: false,
        validate: false,
        hasBeenOpened: false
    },
    osbShoeErrors: {
        message: [],
        stock: false,
        brand: false,
        shoeType: false,
        soleType: false,
        last: false,
        sizeLeft: false,
        widthLeft: false,
        sizeRight: false,
        widthRight: false,
        color: false,
        productNr: false
    }
}
