import React, { useEffect, useState } from 'react'
import { ThemeProvider, Container, Box, Snackbar, Alert, AlertTitle } from '@mui/material'
import './style.css'
import CssBaseline from '@mui/material/CssBaseline'
import { useSelector } from 'react-redux'

// Styling
import useStyles from './style'
import { Routes, Route } from 'react-router-dom'
import HomePage from './HomePage'
import { LOGOUT_ENDPOINT } from '../env'
import Theme from '../lib/themeProvider'
import { withStyles } from '@mui/styles'
import AppTitle from '../components/AppTitle'
import OSBPage from './OSBPage'
import RepairPage from './RepairPage'
import OverviewPage from './OverviewPage'
import OVACPage from './OVACPage'
import BreakTheGlassDialog from '../components/BreakTheGlassDialog'
import UserService from '../lib/userService'
import api from '../lib/api'
import moment from 'moment'

/**
 * Logout function
 *
 * @return null
 */
function Logout () {
    window.location.replace(LOGOUT_ENDPOINT)
    return null
}

// eslint-disable-next-line no-unused-vars
const App = ({ classes }) => {
    const [closeToExpired, setCloseToExpired] = useState(false)
    const [expired, setExpired] = useState(false)
    const [expiresAt, setExpiresAt] = useState(moment())
    const other = useSelector((state) => state.other)
    const theme = Theme.theme(other.backend)

    useEffect(() => {
        UserService.refreshProces(checkSharepoint)
    }, [])

    /**
     *
     */
    function checkSharepoint() {
        if (UserService.hasRole('sharepoint-tester')) {
            if(api.expires_at === "") return
            const date = moment.now()
            setExpiresAt(moment(api.expires_at))

            if(((api.expires_at - date) / 1000) < 180) {
                setExpired(true)
                setCloseToExpired(false)
                return
            } else if (((api.expires_at - date) / 1000) > 180 && ((api.expires_at - date) / 1000) < 600){
                setCloseToExpired(true)
                setExpired(false)
                return
            }
            setCloseToExpired(false)
            setExpired(false)
        }
    }

    /**
     *
     */
    function refreshSession() {
        if (UserService.hasRole('sharepoint-tester')) {
            window.location.replace(api.accountLinkUrl)
        } else {
            window.location.replace('/')
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Container >
                <Box>
                    <AppTitle title={'Bestelmodule'} />
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/osb" element={<OSBPage />} />
                        <Route path="/ovac" element={<OVACPage />} />
                        <Route path="/repair" element={<RepairPage />} />
                        <Route path="/logout" element={<Logout/>}/>
                        <Route path="/overview" element={<OverviewPage/>}/>
                    </Routes>
                    <Snackbar
                        open={closeToExpired}
                    >
                        <Alert variant="filled" severity="warning" onClick={refreshSession}>
                            <AlertTitle>Pas op</AlertTitle>
                            De sessie loopt af om {expiresAt.format("HH:mm")}
                        </Alert>
                    </Snackbar>
                    <Snackbar
                        open={expired}
                    >
                        <Alert variant="filled" severity="error" onClick={refreshSession}>
                            <AlertTitle>Error</AlertTitle>
                            De sessie is gestopt. Klik om opnieuw te beginnen.
                        </Alert>
                    </Snackbar>
                </Box>
                <BreakTheGlassDialog/>
            </Container>
        </ThemeProvider>
    )
}

export default withStyles(useStyles)(App)
