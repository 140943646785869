import React from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControl,
    FormGroup,
    FormLabel,
    Grid,
    Typography,
    Autocomplete,
    TextField,
    FormControlLabel,
    Checkbox,
    Box
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { useSelector, useDispatch } from 'react-redux'
import { osbShoeAccordion, osbShoeData } from '../../store/reducers/osb'

import useStyles from './style'
import { withStyles } from '@mui/styles'

import dataProvider from '../../lib/dataProvider'

const osbData = dataProvider.osb

const OSB = ({ classes }) => {
    // state management
    const dispatch = useDispatch()
    const osb = useSelector((state) => state.osbShoe.osbShoeData)
    const error = useSelector((state) => state.osbShoe.osbShoeErrors)
    const tab = useSelector((state) => state.osbShoe.osbShoeAccordion)
    const orderType = useSelector((state) => state.general.orderType)
    // const other = useSelector((state) => state.other)

    /**
     * Toggle the accordion.
     */
    function toggleAccordion () {
        dispatch(osbShoeAccordion({ key: 'accordion', value: !tab.accordion }))
    }

    /**
     * Set send to podoIt true or false.
     *
     * @param {Boolean} event - bool
     */
    function setCheckedInsole (event) {
        dispatch(osbShoeData({ key: 'insole', value: event.target.checked }))
    };

    return (
        <Accordion defaultExpanded={true} expanded={tab.accordion}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={toggleAccordion}
                className={!tab.accordion
                    ? tab.hasBeenOpened
                        ? tab.validate
                            ? classes.accordionSuccess
                            : classes.accordionError
                        : null
                    : null}
            >
                <Typography
                    className={!tab.accordion
                        ? tab.hasBeenOpened
                            ? tab.validate
                                ? classes.titleSuccess
                                : classes.titleError
                            : classes.title
                        : classes.title}>{orderType} Schoen</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <FormGroup>
                    <Grid container spacing={4}>
                        <Grid item xs={4}>
                            <FormControl
                                fullWidth
                                error={error.stock}
                            >
                                <FormLabel>Bestellen/ Voorraad*</FormLabel>
                                <Autocomplete
                                    name="bestellen-vooraad"
                                    id="bestellen" label="bestellen"
                                    value={osb.stock}
                                    onChange={(event, value) => dispatch(osbShoeData({ key: 'stock', value }))}
                                    options={osbData.stock}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.stock}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl
                                fullWidth
                                error={error.brand}
                            >
                                <FormLabel>Merk*</FormLabel>
                                <Box className={classes.autoComplete}></Box>
                                <TextField
                                    type='text'
                                    value={osb.brand}
                                    error={error.brand}
                                    onChange={event => dispatch(osbShoeData({ key: 'brand', value: event.target.value }))}/>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl
                                fullWidth
                                error={error.shoeType}
                            >
                                <FormLabel>Schoen type*</FormLabel>
                                <Autocomplete
                                    value={osb.shoeType}
                                    onChange={(event, value) => dispatch(osbShoeData({ key: 'shoeType', value }))}
                                    options={osbData.shoeType}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.shoeType}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl
                                fullWidth
                                error={error.productNr}
                            >
                                <FormLabel>Artikel nummer*</FormLabel>
                                <Box className={classes.autoComplete}></Box>
                                <TextField
                                    type='text'
                                    value={osb.productNr}
                                    error={error.productNr}
                                    onChange={event => dispatch(osbShoeData({ key: 'productNr', value: event.target.value }))} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl
                                fullWidth
                                error={error.last}
                            >
                                <FormLabel>Leest type</FormLabel>
                                <Box className={classes.autoComplete}></Box>
                                <TextField
                                    type='text'
                                    value={osb.last}
                                    error={error.last}
                                    onChange={event => dispatch(osbShoeData({ key: 'last', value: event.target.value }))} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl
                                fullWidth
                                error={error.color}
                            >
                                <FormLabel>Kleur*</FormLabel>
                                <Box className={classes.autoComplete}></Box>
                                <TextField
                                    type='text'
                                    value={osb.color}
                                    error={error.color}
                                    onChange={event => dispatch(osbShoeData({ key: 'color', value: event.target.value }))} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl
                                fullWidth
                                error={error.soleType}
                            >
                                <FormLabel>Zool type</FormLabel>
                                <Box className={classes.autoComplete}></Box>
                                <TextField
                                    type='text'
                                    value={osb.soleType}
                                    error={error.soleType}
                                    onChange={event => dispatch(osbShoeData({ key: 'soleType', value: event.target.value }))}/>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={error.sizeLeft}
                            >
                                <FormLabel>Maat links*</FormLabel>
                                <Box className={classes.autoComplete}></Box>
                                <TextField
                                    type='number'
                                    placeholder='0'
                                    value={osb.sizeLeft}
                                    error={error.sizeLeft}
                                    onChange={event => dispatch(osbShoeData({ key: 'sizeLeft', value: event.target.value }))} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={error.sizeRight}
                            >
                                <FormLabel>Maat rechts*</FormLabel>
                                <Box className={classes.autoComplete}></Box>
                                <TextField
                                    type='number'
                                    placeholder='0'
                                    value={osb.sizeRight}
                                    error={error.sizeRight}
                                    onChange={event => dispatch(osbShoeData({ key: 'sizeRight', value: event.target.value }))} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={error.widthLeft}
                            >
                                <FormLabel>Wijdte links*</FormLabel>
                                <Box className={classes.autoComplete}></Box>
                                <TextField
                                    type='text'
                                    value={osb.widthLeft}
                                    error={error.widthLeft}
                                    onChange={event => dispatch(osbShoeData({ key: 'widthLeft', value: event.target.value }))} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={error.widthRight}
                            >
                                <FormLabel>Wijdte rechts*</FormLabel>
                                <Box className={classes.autoComplete}></Box>
                                <TextField
                                    type='text'
                                    value={osb.widthRight}
                                    error={error.widthRight}
                                    onChange={event => dispatch(osbShoeData({ key: 'widthRight', value: event.target.value }))} />
                            </FormControl>
                        </Grid>
                    </Grid>
                </FormGroup>
                <Grid container spacing={4}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}>
                        <FormControl
                            fullWidth
                        >
                            <FormLabel>Opmerking OSB schoen</FormLabel>
                            <Box className={classes.autoComplete}></Box>
                            <TextField
                                type='text'
                                value={osb.note}
                                multiline
                                rows={3}
                                onChange={event => dispatch(osbShoeData({ key: 'note', value: event.target.value }))} />
                        </FormControl>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

export default withStyles(useStyles)(OSB)
