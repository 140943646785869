import axios from 'axios'
import { REACT_APP_API_URL, IDENTITY_REALM, IDENTITY_URL, FOLDER_LOCATION, BACKEND } from '../env'
import UserService from './userService'
import moment from 'moment'
import renderForm from './renderForm'

/**
 * Class API
 * @version 1.0.0
 */
class API {
    /**
     * Get api url.
     *
     * @version 1.0.0
     */
    get apiUrl () {
        return REACT_APP_API_URL
    }

    /**
     * Constructor.
     * @version 1.0.0
     */
    constructor () {
        // this.handleErrors = this.handleErrors.bind(this);
        this.backendUrl = BACKEND
        this.sharepointToken = ''
        this.accountLinkUrl = '/'

        this.wittepoelSite = 'voetencentrumwender590.sharepoint.com,ab3332f1-3ffe-44c2-b8b0-8d4c8d694cd7,260264f5-ccb4-4955-b8e2-5825a9cd9cb5'
        this.wittepoelDriveID = 'b!8TIzq_4_wkS4sI1MjWlM1_VkAia0zFVJuOJYJanNnLW9LdzewnjNQql9DzW-XD7R'

        this.wenderSite = 'voetencentrumwender590.sharepoint.com,5db3cbfd-afb9-4e2c-bebf-bf20b096ff68,27b33d2a-8432-41a2-926d-18a075b384c9'
        this.wenderDriveID = 'b!_cuzXbmvLE6-v78gsJb_aCo9sycyhKJBkm0YoHWzhMkH9h1t788xSYzPfgPpKPWP'

        this.expires_at = ''
    }

    /**
     * Handle the retrieved error from the API
     *
     * @param {Object} errors - object with error information
     */
    handleASPErrors (errors) {
        try {
            if (errors) {
                const message = ['The following errors occurred:']
                for (const key in errors) {
                    const errs = errors[key]
                    message.push(`  ${key}`)
                    for (const value of errs) {
                        message.push(`   - ${value}`)
                    }
                }
                console.error('ASP.NET\n', message.join('\n'))
            }
        } catch (e) {
            console.error('Response.errors is not as was expected', e, errors)
        }
    }

    /**
     * Handles shared internal logic for requests.
     * The response can be handled using both awaiting the result and using promises
     *
     * @param  {string   } method    The method, GET, POST, you name it.
     * @param  {string   } path      The path of the request. Only requests to
     * @param  {Object   } body      The body of the request. This will be turned into JSON automatically.
     * @param  {Object   } query     The query of the request.
     * @param  {Function?} onSuccess The callback called on a successful fetch. it is optional.
     * @param  {Function?} onError   The callback called when an error occurs.  it is optional.
     * @return {Promise<Object>} a promise with the json content
     */
    async JSONRequest (method, path, body, onSuccess, onError) {
        if (this.backendUrl === '') {
            onError('Geen backend geselecteerd')
            return
        }

        try {
            const response = await axios({
                method,
                url: `${this.apiUrl}${path}`,
                data: body,
                headers: {
                    Token: UserService.getToken(),
                    Backend: this.backendUrl,
                    Realm: IDENTITY_REALM,
                    KeycloakEndpoint: IDENTITY_URL,
                    isBootform: 'true'
                }
            })
            if (onSuccess) onSuccess(response.data)
            return response
        } catch (error) {
            if (error?.response?.status === 400) {
                this.handleASPErrors(error.response.data.errors)
            }
            if (onError) onError(error)
        }
    }

    /**
     * A simplified version of {@link #JSONRequest} for get requests. can be awaited
     * @param   {String   } path      The path of the request. e.g. '/Items'
     * @param   {Function?} onSuccess A callback called if the request was successful. This is optional.
     * @param   {Function?} onError   A callback called if the request failed.         This is optional.
     * @returns {Promise<Object>} a promise with the json content
     */
    JSONGet (path, onSuccess, onError) {
        return this.JSONRequest('GET', path, {}, onSuccess, onError)
    }

    /**
     * Get patients.
     *
     * @param {Function} onSuccess - callback function.
     * @param {Function} onError - error callback function.
     * @param {FormData} patienformDatatNumber - number of patient
     * @version 1.0.0
     */
    async postInsole (formData, onSuccess, onError) {
        return this.JSONRequest('POST', '/podoit/unity', formData, onSuccess, onError)
    }

    /**
     * Get patients.
     *
     * @param {Function} onSuccess - callback function.
     * @param {Function} onError - error callback function.
     * @param {Number} patientNumber - number of patient
     * @version 1.0.0
     */
    patients (patientNumber, onSuccess, onError) {
        return this.JSONGet(`/podoit/patient/${patientNumber}`, onSuccess, onError)
    }

    /**
     * Get therapist.
     *
     * @param {Function} onSuccess - callback function.
     * @param {Function} onError - error callback function.
     * @version 1.0.0
     */
    therapists (onSuccess, onFailure) {
        return this.JSONGet('/podoit/therapist', onSuccess, onFailure)
    }

    /**
     *
     * @param {*} callback -
     * @param {*} errorCallback -
     */
    location (callback, errorCallback) {
        return this.JSONGet('/podoit/location', callback, errorCallback)
    }

    /**
     * Places a new order. can be awaited
     * @param order the data of the order to place
     * @param onSuccess
     * @param onError
     * @returns {Promise<Object>}
     */
    placeOrder (order, onSuccess, onError) {
        if (order.orderType === 'fits' || order.orderType === 'fitsCustom') {
            return this.JSONRequest('POST', '/podoit/order/fits', order, onSuccess, onError)
        } else {
            return this.JSONRequest('POST', '/podoit/order/insole', order, onSuccess, onError)
        }
    }

    /**
     *
     * @param {*} obj
     * @returns
     */
    recursiveStringify (obj) {
        if (obj && typeof obj === 'object' && !moment.isMoment(obj)) {
            const result = Array.isArray(obj) ? [] : {}
            for (const key in obj) {
                result[key] = this.recursiveStringify(obj[key])
            }
            return result
        }

        if (moment.isMoment(obj)) {
            return obj.toISOString
        } else {
            return obj
        }
    }

    /**
     * Places a new order. can be awaited
     * @param order the data of the order to place
     * @param onSuccess
     * @param onError
     * @returns {Promise<Object>}
     */
    placeOrderBootform (order, onSuccess, onError) {
        const processedObject = this.recursiveStringify(order)
        return this.JSONRequest('POST', '/podoit/order/bootForm', processedObject, onSuccess, onError)
    }

    /**
     * Gets the orders from the server. can be awaited
     * @param {Object} patientID the id of the patient for the order to get
     * @param {Function?} onSuccess a callback for success
     * @param {Function?} onError   a callback for errors
     * @returns {Promise<Object>} response
     */
    getOrder (patientID, orderType, onSuccess, onError) {
        return this.JSONGet(`/podoit/order?number=${patientID}&orderType=${orderType}`, onSuccess, onError)
    }

    /**
     * Update the backend URL
     *
     * @param {String} backend - the selected backend url
     */
    setBackend (backend) {
        this.backendUrl = backend
    }

    /**
     * Set the sharepoint token
     *
     * @param {String} token - the received token.
     */
    setSharepointInformation (microsoftResponse, queryParameters) {
        const response = JSON.parse(microsoftResponse)

        const patientNr = queryParameters.get('patient')
        const patientNumber = patientNr === null ? '' : `&patient=${patientNr}`

        if (response.error_description === 'linked token is expired' && queryParameters.get('redirected') === null) {
            const redirectURL = encodeURIComponent(`https://${window.location.host}${window.location.pathname}?redirected=true${patientNumber}`)
            window.location.replace(`${response['account-link-url']}&redirect_uri=${redirectURL}`)
            return
        }

        this.sharepointToken = response.access_token
        this.accountLinkUrl = `${response['account-link-url']}&redirect_uri=https://${window.location.host}`
        
        const exp = response.expires_in * 1000
        const date = moment.now()
        this.expires_at = exp + date

        if (response.expires_in < 2400 && queryParameters.get('redirected') === null) {
            const redirectURL = encodeURIComponent(`https://${window.location.host}${window.location.pathname}?redirected=true${patientNumber}`)
            window.location.replace(`${response['account-link-url']}&redirect_uri=${redirectURL}`)
        }
    }

    /**
     *
     * @param {*} rhinoJSON
     */
    async uploadToSharepoint (rhinoJSON, fileName, onSuccess, onError) {
        const axios = require('axios')
        const config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: `https://graph.microsoft.com/v1.0/sites/voetencentrumwender590.sharepoint.com,5db3cbfd-afb9-4e2c-bebf-bf20b096ff68,27b33d2a-8432-41a2-926d-18a075b384c9/drives/b!_cuzXbmvLE6-v78gsJb_aCo9sycyhKJBkm0YoHWzhMkH9h1t788xSYzPfgPpKPWP/root:/Centraal modelleren (project)/ExportsWebapplicatieSlimModelleren/${FOLDER_LOCATION}/${fileName}:/content`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.sharepointToken
            },
            data: rhinoJSON
        }

        axios.request(config)
            .then((response) => {
                onSuccess(response)
            })
            .catch((error) => {
                onError(error)
            })
    }

    /**
     *
     * @param {*} pdf
     * @param {*} orderType
     * @param {*} patient
     * @param {*} fileName
     * @param {*} onSuccess
     */
    async postPDFPodoIT (pdf, orderType, patient, fileName) {
        const axios = require('axios')
        const config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: `${REACT_APP_API_URL}/podoit/order/pdf?orderType=${orderType}&id=${patient.patientData.id}&filename=${fileName}`,
            headers: {
                'Content-Type': 'application/pdf',
                Token: UserService.getToken(),
                Backend: this.backendUrl
            },
            data: pdf
        }

        await axios.request(config)
            .then(async () => { })
            .catch((error) => {
                console.error(error)
            })
    }

    /**
     *
     */
    async retrievePDF (fullstate, sendToSharepoint, onSuccess, orderType, patient) {
        const today = moment().format('DD-MM-YYYY')
        const fileName = `${patient.patientData.number.replace(' ', '')}_${orderType}_${today}.pdf`
        let html = renderForm(fullstate).outerHTML

        const data = {
            options: {
                format: 'a4',
                landscape: false,
                margin: { top: '4', right: '4', bottom: '4', left: '4' },
                scale: 1
            },
            html,
            fileName,
            patientID: patient.patientData.id
        }
        const requestOptions = {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Token: UserService.getToken(),
                Backend: this.backendUrl
            },
            redirect: 'follow',
            body: JSON.stringify(data)
        }
        let blob
        fetch(`${REACT_APP_API_URL}/podoit/PDF`, requestOptions)
            .then(async (response) => {
                console.log(response)

                blob = await response.blob()
                console.log(response)
                const newBlob = new Blob([blob])
                this.postPDFPodoIT(newBlob, orderType, patient, fileName)

                // download if its not supposed to be sent to sharepoint
                if (!sendToSharepoint) {
                    const link = document.createElement('a')
                    // create a blobURI pointing to our Blob
                    link.href = URL.createObjectURL(blob)
                    link.download = fileName
                    // some browser needs the anchor to be in the doc
                    document.body.append(link)
                    link.click()
                    link.remove()
                    // in case the Blob uses a lot of memory
                    setTimeout(() => URL.revokeObjectURL(link.href), 7000)
                    onSuccess()
                    return
                }

                const axios = require('axios')
                const config = {
                    method: 'put',
                    maxBodyLength: Infinity,
                    url: `https://graph.microsoft.com/v1.0/sites/${this.wittepoelSite}/drives/${this.wittepoelDriveID}/root:/Bestellingen/${fileName}:/content`,
                    headers: {
                        'Content-Type': 'application/pdf',
                        Authorization: `Bearer ${this.sharepointToken}`
                    },
                    data: newBlob
                }

                await axios.request(config)
                    .then(async (response) => {
                        onSuccess()
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            })
            .catch((error) => console.error(error))
    }
}

 
export default (new API())
