export const initialState = {
    repairData: {
        note: '',
        /** @type {Boolean} */
        warranty: false,
        /** @type {Boolean} */
        service: false,
        /** @type {'' | Number} */
        customerCosts: '',
        /** @type {'' | Number} */
        institutionCosts: '',
        /** @type {'' | Number} */
        insuranceCosts: '',
        left: {
            rubberSole: false,
            leatherSole: false,
            heels: false,
            generalSole: false,
            heelBuffer: false,
            heelRounding: false,
            mendHooksOrRings: false,
            mendZipper: false,
            rocker: '',
            heel: '',
            ball: '',
            toe: '',
            correction: '',
            correctionHeight: '',
            lateral: '',
            medial: '',
            soleStiffening: '',
            stretch: '',
            solePad: '',
            closureAdjust: '',
            tongueVelcro: false,
            replaceAdhesive: false,
            extendVelcro: false,
            shortenVelcro: false,
            openPosition: '',
            tongueStitching: false,
            heelLining: false,
            repairStitching: false,
            toePieces: false,
            adhesive: false,
            newCover: false,
            heelElevation: '',
            /* insoles and foil */
            shoeSize: '',
            shoeWidth: '',
            ucbl: '',
            forefootThickness: '',
            insoleMaterial: '',
            insoleLength: '',
            foilOverLast: false,
            foilOverExistingSupplement: false
        },
        right: {
            rubberSole: false,
            leatherSole: false,
            heels: false,
            generalSole: false,
            heelBuffer: false,
            heelRounding: false,
            mendHooksOrRings: false,
            mendZipper: false,
            rocker: '',
            heel: '',
            ball: '',
            toe: '',
            correction: '',
            correctionHeight: '',
            lateral: '',
            medial: '',
            soleStiffening: '',
            stretch: '',
            solePad: '',
            closureAdjust: '',
            tongueVelcro: false,
            replaceAdhesive: false,
            extendVelcro: false,
            shortenVelcro: false,
            openPosition: '',
            tongueStitching: false,
            heelLining: false,
            repairStitching: false,
            toePieces: false,
            adhesive: false,
            newCover: false,
            heelElevation: '',
            /* insoles and foil */
            shoeSize: '',
            shoeWidth: '',
            ucbl: '',
            forefootThickness: '',
            insoleMaterial: '',
            insoleLength: '',
            foilOverLast: false,
            foilOverExistingSupplement: false
        }
    },
    repairAccordion: {
        accordion: false,
        validate: false,
        hasBeenOpened: false
    },
    repairErrors: {
        message: [],
        customerCosts: false,
        institutionCosts: false,
        insuranceCosts: false,
        left: {
            rubberSole: false,
            leatherSole: false,
            heels: false,
            generalSole: false,
            heelBuffer: false,
            heelRounding: false,
            mendHooksOrRings: false,
            mendZipper: false,
            rocker: false,
            heel: false,
            ball: false,
            toe: false,
            correction: false,
            correctionHeight: false,
            lateral: false,
            medial: false,
            soleStiffening: false,
            stretch: false,
            solePad: false,
            closureAdjust: false,
            tongueVelcro: false,
            replaceAdhesive: false,
            extendVelcro: false,
            shortenVelcro: false,
            openPosition: false,
            tongueStitching: false,
            heelLining: false,
            repairStitching: false,
            toePieces: false,
            adhesive: false,
            newCover: false,
            heelElevation: false,
            /* insoles and foil */
            shoeSize: false,
            shoeWidth: false,
            ucbl: false,
            forefootThickness: false,
            insoleMaterial: false,
            insoleLength: false,
            foilOverLast: false,
            foilOverExistingSupplement: false
        },
        right: {
            rubberSole: false,
            leatherSole: false,
            heels: false,
            generalSole: false,
            heelBuffer: false,
            heelRounding: false,
            mendHooksOrRings: false,
            mendZipper: false,
            rocker: false,
            heel: false,
            ball: false,
            toe: false,
            correction: false,
            correctionHeight: false,
            lateral: false,
            medial: false,
            soleStiffening: false,
            stretch: false,
            solePad: false,
            closureAdjust: false,
            tongueVelcro: false,
            replaceAdhesive: false,
            extendVelcro: false,
            shortenVelcro: false,
            openPosition: false,
            tongueStitching: false,
            heelLining: false,
            repairStitching: false,
            toePieces: false,
            adhesive: false,
            newCover: false,
            heelElevation: false,
            /* insoles and foil */
            shoeSize: false,
            shoeWidth: false,
            ucbl: false,
            forefootThickness: false,
            insoleMaterial: false,
            insoleLength: false,
            foilOverLast: false,
            foilOverExistingSupplement: false
        }
    }
}
