import React from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary as MuiAccordionSummary,
    FormControl,
    FormGroup,
    FormLabel,
    Grid,
    Typography,
    Autocomplete,
    TextField,
    Box,
    styled,
    FormControlLabel,
    Checkbox,
    Select,
    MenuItem,
    ListItemText,
    OutlinedInput
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { useSelector, useDispatch } from 'react-redux'
import { osaPatternAccordion, osaPatternData } from '../../store/reducers/osaPattern'

import useStyles from './style'
import { withStyles } from '@mui/styles'

import dataProvider from '../../lib/dataProvider'
import { deepCopy } from '../../lib/util'
import { osaLowerData } from '../../store/reducers/osaLower'

const data = dataProvider.osaPattern
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
}

const osaPattern = ({ classes }) => {
    // state management
    const dispatch = useDispatch()
    const osaPattern = useSelector((state) => state.osaPattern.osaPatternData)
    const error = useSelector((state) => state.osaPattern.osaPatternErrors)
    const tab = useSelector((state) => state.osaPattern.osaPatternAccordion)
    const [expanded, setExpanded] = React.useState(['panel1'])

    /**
     * Handle tab change
     * @param {*} event - event
     * @param {*} newValue - selected tab
     */
    function handleChange (accordionValue) {
        const newExpanded = deepCopy(expanded)

        if (newExpanded.includes(accordionValue)) {
            newExpanded.splice(newExpanded.indexOf(accordionValue), 1)
        } else {
            newExpanded.push(accordionValue)
        }

        setExpanded(newExpanded)
    }

    /**
     *
     * @param {*} event
     */
    function handleChangeMultipleSelect (event) {
        const { target: { value } } = event
        dispatch(osaPatternData({ key: 'laces', value: typeof value === 'string' ? value.split(',') : value }))

        if (!value.includes('Haken')) {
            dispatch(osaPatternData({ key: 'laceHooks', value: null }))
            dispatch(osaPatternData({ key: 'laceHooksAmount', value: '' }))
        } else if (!value.includes('Ringen')) {
            dispatch(osaPatternData({ key: 'laceRings', value: null }))
            dispatch(osaPatternData({ key: 'laceRingsAmount', value: '' }))
        }
    }

    /**
     * Toggle the accordion.
     */
    function toggleAccordion () {
        dispatch(osaPatternAccordion({ key: 'accordion', value: !tab.accordion }))
    }

    /**
     *
     * @param {*} value
     * @param {*} side
     */
    function setHeelHeight (value, side) {
        dispatch(osaPatternData({ key: 'heelHeight', side, value }))
        dispatch(osaLowerData({ key: 'heelHeight', side, value }))
    }

    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary
            expandIcon={<ExpandMore sx={{ fontSize: '0.9rem' }} />}
            {...props}
        />
    ))(({ theme }) => ({
        backgroundColor: 'rgba(0, 0, 0, .02)',
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(180deg)'
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1)
        }
    }))

    return (
        <Accordion defaultExpanded={true} expanded={tab.accordion}>
            <MuiAccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={toggleAccordion}
                className={!tab.accordion
                    ? tab.hasBeenOpened
                        ? tab.validate
                            ? classes.accordionSuccess
                            : classes.accordionError
                        : null
                    : null}
            >
                <Typography
                    className={!tab.accordion
                        ? tab.hasBeenOpened
                            ? tab.validate
                                ? classes.titleSuccess
                                : classes.titleError
                            : classes.title
                        : classes.title}>OSA Patroon</Typography>
            </MuiAccordionSummary>
            <AccordionDetails>
                <FormGroup>
                    <Grid container spacing={4}>
                        <Grid item xs={3}>
                            <FormControl
                                fullWidth
                                error={error.supplier}
                            >
                                <FormLabel>Leverancier*</FormLabel>
                                <Autocomplete
                                    value={osaPattern.supplier}
                                    onChange={(event, value) => dispatch(osaPatternData({ key: 'supplier', value }))}
                                    options={data.supplier}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.supplier}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl
                                fullWidth
                                error={error.modelHeritage}
                            >
                                <FormLabel>Model komt van*</FormLabel>
                                <Autocomplete
                                    value={osaPattern.modelHeritage}
                                    onChange={(event, value) => dispatch(osaPatternData({ key: 'modelHeritage', value }))}
                                    options={data.modelHeritage}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.modelHeritage}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl
                                fullWidth
                                error={error.modelNumber}
                            >
                                <FormLabel>Modelnummer</FormLabel>
                                <Box className={classes.autoComplete}></Box>
                                <TextField
                                    type='text'
                                    value={osaPattern.modelNumber}
                                    error={error.modelNumber}
                                    onChange={event => dispatch(osaPatternData({ key: 'modelNumber', value: event.target.value }))}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl
                                fullWidth
                                error={error.description}
                            >
                                <FormLabel>Omschrijving</FormLabel>
                                <Box className={classes.autoComplete}></Box>
                                <TextField
                                    type='text'
                                    value={osaPattern.description}
                                    error={error.description}
                                    onChange={event => dispatch(osaPatternData({ key: 'description', value: event.target.value }))}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}></Grid>
                    </Grid>

                    <Accordion elevation={0} disableGutters expanded={expanded.includes('panel1')} onChange={(event) => handleChange('panel1')}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>Overleer hoofdsoort</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={4}>
                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                        error={error.description}
                                    >
                                        <FormLabel>Nummer*</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='text'
                                            value={osaPattern.mainLeather.number}
                                            error={error.mainLeather.number}
                                            onChange={event => dispatch(osaPatternData({ key: 'mainLeather', subKey: 'number', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                        error={error.mainLeather.name}
                                    >
                                        <FormLabel>Naam*</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='text'
                                            value={osaPattern.mainLeather.name}
                                            error={error.mainLeather.name}
                                            onChange={event => dispatch(osaPatternData({ key: 'mainLeather', subKey: 'name', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion elevation={0} expanded={expanded.includes('panel2')} onChange={(event) => handleChange('panel2')}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>Overleer bijsoort 1</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={4}>
                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                        error={error.firstExtraLeather.number}
                                    >
                                        <FormLabel>Nummer*</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='text'
                                            value={osaPattern.firstExtraLeather.number}
                                            error={error.firstExtraLeather.number}
                                            onChange={event => dispatch(osaPatternData({ key: 'firstExtraLeather', subKey: 'number', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                        error={error.firstExtraLeather.name}
                                    >
                                        <FormLabel>Naam*</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='text'
                                            value={osaPattern.firstExtraLeather.name}
                                            error={error.firstExtraLeather.name}
                                            onChange={event => dispatch(osaPatternData({ key: 'firstExtraLeather', subKey: 'name', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion elevation={0} expanded={expanded.includes('panel3')} onChange={(event) => handleChange('panel3')}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>Overleer bijsoort 2</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={4}>
                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                        error={error.secondExtraLeather.number}
                                    >
                                        <FormLabel>Nummer*</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='text'
                                            value={osaPattern.secondExtraLeather.number}
                                            error={error.secondExtraLeather.number}
                                            onChange={event => dispatch(osaPatternData({ key: 'secondExtraLeather', subKey: 'number', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                        error={error.secondExtraLeather.name}
                                    >
                                        <FormLabel>Naam*</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='text'
                                            value={osaPattern.secondExtraLeather.name}
                                            error={error.secondExtraLeather.name}
                                            onChange={event => dispatch(osaPatternData({ key: 'secondExtraLeather', subKey: 'name', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion elevation={0} expanded={expanded.includes('panel4')} onChange={(event) => handleChange('panel4')}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>Overleer bijsoort 3</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={4}>
                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                        error={error.thirdExtraLeather.number}
                                    >
                                        <FormLabel>Nummer*</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='text'
                                            value={osaPattern.thirdExtraLeather.number}
                                            error={error.thirdExtraLeather.number}
                                            onChange={event => dispatch(osaPatternData({ key: 'thirdExtraLeather', subKey: 'number', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                        error={error.thirdExtraLeather.number}
                                    >
                                        <FormLabel>Naam*</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='text'
                                            value={osaPattern.thirdExtraLeather.name}
                                            error={error.thirdExtraLeather.name}
                                            onChange={event => dispatch(osaPatternData({ key: 'thirdExtraLeather', subKey: 'name', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    <Grid container spacing={4}>
                        <Grid item xs={12}></Grid>

                        <Grid item xs={4}>
                            <FormControl
                                fullWidth
                                error={error.collarPadding}
                            >
                                <FormLabel>Kraagpolster</FormLabel>
                                <Autocomplete
                                    value={osaPattern.collarPadding}
                                    onChange={(event, value) => dispatch(osaPatternData({ key: 'collarPadding', value }))}
                                    options={data.collarPadding}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.collarPadding}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl
                                fullWidth
                                error={error.collarPaddingType}
                            >
                                <FormLabel>Kraagpolster soort</FormLabel>
                                <Autocomplete
                                    value={osaPattern.collarPaddingType}
                                    onChange={(event, value) => dispatch(osaPatternData({ key: 'collarPaddingType', value }))}
                                    options={data.collarPaddingType}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.collarPaddingType}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={error.left.collarPaddingThickness}
                            >
                                <FormLabel>Kraagpolster dikte links</FormLabel>
                                <Autocomplete
                                    value={osaPattern.left.collarPaddingThickness}
                                    onChange={(event, value) => dispatch(osaPatternData({ key: 'collarPaddingThickness', side: 'left', value }))}
                                    options={data.collarPaddingThickness}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.left.collarPaddingThickness}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={error.right.collarPaddingThickness}
                            >
                                <FormLabel>Kraagpolster dikte rechts</FormLabel>
                                <Autocomplete
                                    value={osaPattern.right.collarPaddingThickness}
                                    onChange={(event, value) => dispatch(osaPatternData({ key: 'collarPaddingThickness', side: 'right', value }))}
                                    options={data.collarPaddingThickness}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.right.collarPaddingThickness}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl
                                fullWidth
                                error={error.solePadding}
                            >
                                <FormControl
                                    fullWidth
                                    error={error.right.solePadding}
                                >
                                    <FormLabel>Tongpolster</FormLabel>
                                    <Autocomplete
                                        value={osaPattern.right.solePadding}
                                        onChange={(event, value) => dispatch(osaPatternData({ key: 'solePadding', value }))}
                                        options={data.solePadding}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.right.solePadding}
                                                {...params} />
                                        } />
                                </FormControl>
                            </FormControl>
                        </Grid>
                        <Grid item xs={8}></Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={error.left.solePaddingThickness}
                            >
                                <FormLabel>Tongpolster dikte links</FormLabel>
                                <Autocomplete
                                    value={osaPattern.left.solePaddingThickness}
                                    onChange={(event, value) => dispatch(osaPatternData({ key: 'solePaddingThickness', side: 'left', value }))}
                                    options={data.solePaddingThickness}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.left.solePaddingThickness}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={error.right.solePaddingThickness}
                            >
                                <FormLabel>Tongpolster dikte rechts</FormLabel>
                                <Autocomplete
                                    value={osaPattern.right.solePaddingThickness}
                                    onChange={(event, value) => dispatch(osaPatternData({ key: 'solePaddingThickness', side: 'right', value }))}
                                    options={data.solePaddingThickness}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.right.solePaddingThickness}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={error.left.upperHeight}
                            >
                                <FormLabel>Schachthoogte links (mm)</FormLabel>
                                <Box className={classes.autoComplete}></Box>
                                <TextField
                                    type='number'
                                    placeholder={data.upperHeight.min + ' - ' + data.upperHeight.max}
                                    value={osaPattern.left.upperHeight}
                                    error={error.left.upperHeight}
                                    onChange={event => dispatch(osaPatternData({ key: 'upperHeight', side: 'left', value: event.target.value }))}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={error.right.upperHeight}
                            >
                                <FormLabel>Schachthoogte rechts (mm)</FormLabel>
                                <Box className={classes.autoComplete}></Box>
                                <TextField
                                    type='number'
                                    placeholder={data.upperHeight.min + ' - ' + data.upperHeight.max}
                                    value={osaPattern.right.upperHeight}
                                    error={error.right.upperHeight}
                                    onChange={event => dispatch(osaPatternData({ key: 'upperHeight', side: 'right', value: event.target.value }))}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={error.mudguardLeather}
                            >
                                <FormLabel>Spatrand leer</FormLabel>
                                <Autocomplete
                                    value={osaPattern.mudguardLeather}
                                    onChange={(event, value) => dispatch(osaPatternData({ key: 'mudguardLeather', value }))}
                                    options={data.mudguardLeather}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.mudguardLeather}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={error.left.heelHeight}
                            >
                                <FormLabel>Hakhoogte links (mm)</FormLabel>
                                <Box className={classes.autoComplete}></Box>
                                <TextField
                                    type='number'
                                    placeholder={data.heelHeight.min + ' - ' + data.heelHeight.max}
                                    value={osaPattern.left.heelHeight}
                                    error={error.left.heelHeight}
                                    onChange={(event) => setHeelHeight(event.target.value, 'left')}/>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={error.right.heelHeight}
                            >
                                <FormLabel>Hakhoogte rechts (mm)</FormLabel>
                                <Box className={classes.autoComplete}></Box>
                                <TextField
                                    type='number'
                                    placeholder={data.heelHeight.min + ' - ' + data.heelHeight.max}
                                    value={osaPattern.right.heelHeight}
                                    error={error.right.heelHeight}
                                    onChange={(event) => setHeelHeight(event.target.value, 'right')}/>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={error.left.contrefort}
                            >
                                <FormLabel>Contrefort links</FormLabel>
                                <Autocomplete
                                    value={osaPattern.left.contrefort}
                                    onChange={(event, value) => dispatch(osaPatternData({ key: 'contrefort', side: 'left', value }))}
                                    options={data.contrefort}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.left.contrefort}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={error.right.contrefort}
                            >
                                <FormLabel>Contrefort rechts</FormLabel>
                                <Autocomplete
                                    value={osaPattern.right.contrefort}
                                    onChange={(event, value) => dispatch(osaPatternData({ key: 'contrefort', side: 'right', value }))}
                                    options={data.contrefort}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.right.contrefort}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={error.left.lining}
                            >
                                <FormLabel>Voering links</FormLabel>
                                <Autocomplete
                                    value={osaPattern.left.lining}
                                    onChange={(event, value) => dispatch(osaPatternData({ key: 'lining', side: 'left', value }))}
                                    options={data.lining}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.left.lining}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={error.right.lining}
                            >
                                <FormLabel>Voering rechts</FormLabel>
                                <Autocomplete
                                    value={osaPattern.right.lining}
                                    onChange={(event, value) => dispatch(osaPatternData({ key: 'lining', side: 'right', value }))}
                                    options={data.lining}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.right.lining}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={error.developMethod}
                            >
                                <FormLabel>Maakwijze</FormLabel>
                                <Autocomplete
                                    value={osaPattern.developMethod}
                                    onChange={(event, value) => dispatch(osaPatternData({ key: 'developMethod', value }))}
                                    options={data.developMethod}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.developMethod}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={error.left.entryPoint}
                            >
                                <FormLabel>Inschotpunt links</FormLabel>
                                <Autocomplete
                                    value={osaPattern.left.entryPoint}
                                    onChange={(event, value) => dispatch(osaPatternData({ key: 'entryPoint', side: 'left', value }))}
                                    options={data.entryPoint}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.left.entryPoint}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={error.right.entryPoint}
                            >
                                <FormLabel>Inschotpunt rechts</FormLabel>
                                <Autocomplete
                                    value={osaPattern.right.entryPoint}
                                    onChange={(event, value) => dispatch(osaPatternData({ key: 'entryPoint', side: 'right', value }))}
                                    options={data.entryPoint}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.right.entryPoint}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={error.laces}
                            >
                                <FormLabel>Veters</FormLabel>
                                <Box className={classes.autoComplete}></Box>
                                <Select
                                    multiple
                                    value={osaPattern.laces}
                                    onChange={handleChangeMultipleSelect}
                                    input={<OutlinedInput />}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {data.laces.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            <Checkbox checked={osaPattern.laces.indexOf(name) > -1} />
                                            <ListItemText primary={name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            {osaPattern.laces.includes('Ringen') && <FormControl
                                fullWidth
                                error={error.laces}
                            >
                                <FormLabel>Ringen</FormLabel>
                                <Autocomplete
                                    value={osaPattern.laceRings}
                                    onChange={(event, value) => dispatch(osaPatternData({ key: 'laceRings', value }))}
                                    options={data.laceRings}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.laceRings}
                                            {...params} />
                                    } />
                            </FormControl>}
                        </Grid>
                        <Grid item xs={3}>

                            {osaPattern.laces.includes('Haken') && <FormControl
                                fullWidth
                                error={error.laceHooks}
                            >
                                <FormLabel>Haken</FormLabel>
                                <Autocomplete
                                    value={osaPattern.laceHooks}
                                    onChange={(event, value) => dispatch(osaPatternData({ key: 'laceHooks', value }))}
                                    options={data.laceHooks}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.laceHooks}
                                            {...params} />
                                    } />
                            </FormControl>}
                        </Grid>

                        {(osaPattern.laces.includes('Ringen') || osaPattern.laces.includes('Haken')) && <Grid key="0" item xs={6}></Grid>}
                        {osaPattern.laces.includes('Ringen') && !osaPattern.laces.includes('Haken')
                            ? [
                                <Grid key="1" item xs={3}>
                                    <FormControl
                                        fullWidth
                                        error={error.laceRingsAmount}
                                    >
                                        <FormLabel>Aantal ringen</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            value={osaPattern.laceRingsAmount}
                                            error={error.laceRingsAmount}
                                            onChange={event => dispatch(osaPatternData({ key: 'laceRingsAmount', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>,
                                <Grid key="2" item xs={3}></Grid>
                            ]
                            : null
                        }

                        {osaPattern.laces.includes('Ringen') && osaPattern.laces.includes('Haken')
                            ? [
                                <Grid key="1" item xs={3}>
                                    <FormControl
                                        fullWidth
                                        error={error.laceRingsAmount}
                                    >
                                        <FormLabel>Aantal ringen</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            value={osaPattern.laceRingsAmount}
                                            error={error.laceRingsAmount}
                                            onChange={event => dispatch(osaPatternData({ key: 'laceRingsAmount', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>,
                                <Grid key="2" item xs={3}>
                                    <FormControl
                                        fullWidth
                                        error={error.laceHooksAmount}
                                    >
                                        <FormLabel>Aantal haken</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            value={osaPattern.laceHooksAmount}
                                            error={error.laceHooksAmount}
                                            onChange={event => dispatch(osaPatternData({ key: 'laceHooksAmount', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                            ]
                            : null
                        }

                        {osaPattern.laces.includes('Haken') && !osaPattern.laces.includes('Ringen')
                            ? [
                                <Grid key="1" item xs={3}></Grid>,
                                <Grid key="2" item xs={3}>
                                    <FormControl
                                        fullWidth
                                        error={error.laceHooksAmount}
                                    >
                                        <FormLabel>Aantal haken</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            value={osaPattern.laceHooksAmount}
                                            error={error.laceHooksAmount}
                                            onChange={event => dispatch(osaPatternData({ key: 'laceHooksAmount', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                            ]
                            : null
                        }

                        <Grid item xs={3}>
                            <FormControl
                                fullWidth
                            >
                                <FormControlLabel
                                    label="Klittenband"
                                    control={
                                        <Checkbox
                                            checked={osaPattern.velcro}
                                            onChange={event => dispatch(osaPatternData({ key: 'velcro', value: event.target.checked }))} />}

                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            {osaPattern.velcro &&
                            <FormControl
                                fullWidth
                                error={error.velcroAmount}
                            >
                                <FormLabel>Aantal</FormLabel>
                                <Box className={classes.autoComplete}></Box>
                                <TextField
                                    type='number'
                                    value={osaPattern.velcroAmount}
                                    error={error.velcroAmount}
                                    onChange={event => dispatch(osaPatternData({ key: 'velcroAmount', value: event.target.value }))}
                                />
                            </FormControl>}
                        </Grid>
                        <Grid item xs={3}>
                            {osaPattern.velcro &&
                            <FormControl
                                fullWidth
                                error={error.velcroWidth}
                            >
                                <FormLabel>Breedte</FormLabel>
                                <Box className={classes.autoComplete}></Box>
                                <TextField
                                    type='number'
                                    value={osaPattern.velcroWidth}
                                    error={error.velcroWidth}
                                    onChange={event => dispatch(osaPatternData({ key: 'velcroWidth', value: event.target.value }))}
                                />
                            </FormControl>}
                        </Grid>
                        { osaPattern.velcro && <Grid item xs={3}></Grid> }
                        { osaPattern.velcro &&
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                    >
                                        <FormControlLabel
                                            label="Klittenband omdraaien links"
                                            control={
                                                <Checkbox
                                                    checked={osaPattern.left.reverseVelcro}
                                                    onChange={event => dispatch(osaPatternData({ key: 'reverseVelcro', side: 'left', value: event.target.checked }))} />}

                                        />
                                    </FormControl>
                                </Grid>
                        }
                        { osaPattern.velcro &&
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                    >
                                        <FormControlLabel
                                            label="Klittenband omdraaien rechts"
                                            control={
                                                <Checkbox
                                                    checked={osaPattern.right.reverseVelcro}
                                                    onChange={event => dispatch(osaPatternData({ key: 'reverseVelcro', side: 'right', value: event.target.checked }))} />}

                                        />
                                    </FormControl>
                                </Grid>
                        }
                        <Grid item xs={4}>
                            <FormControl
                                fullWidth
                                error={error.zipper}
                            >
                                <FormLabel>Rits</FormLabel>
                                <Autocomplete
                                    value={osaPattern.zipper}
                                    onChange={(event, value) => dispatch(osaPatternData({ key: 'zipper', value }))}
                                    options={data.zipper}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.zipper}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>

                        <Grid item xs={4}>
                            <FormControl
                                fullWidth
                                error={error.elastic}
                            >
                                <FormLabel>Elastiek</FormLabel>
                                <Autocomplete
                                    value={osaPattern.elastic}
                                    onChange={(event, value) => dispatch(osaPatternData({ key: 'elastic', value }))}
                                    options={data.elastic}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.elastic}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>

                        <Grid item xs={4}>
                            <FormControl
                                fullWidth
                                error={error.buckle}
                            >
                                <FormLabel>Gesp</FormLabel>
                                <Autocomplete
                                    value={osaPattern.buckle}
                                    onChange={(event, value) => dispatch(osaPatternData({ key: 'buckle', value }))}
                                    options={data.buckle}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.buckle}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl
                                fullWidth
                            >
                                <FormControlLabel
                                    label="Leesten aangepast"
                                    control={
                                        <Checkbox
                                            checked={osaPattern.changedLasts}
                                            onChange={event => dispatch(osaPatternData({ key: 'changedLasts', value: event.target.checked }))} />}

                                />
                            </FormControl>
                        </Grid>

                    </Grid>
                </FormGroup>
                <Grid container spacing={4}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}>
                        <FormControl
                            fullWidth
                        >
                            <FormLabel>Opmerking patroon</FormLabel>
                            <Box className={classes.autoComplete}></Box>
                            <TextField
                                type='text'
                                value={osaPattern.note}
                                multiline
                                rows={3}
                                onChange={event => dispatch(osaPatternData({ key: 'note', value: event.target.value }))} />
                        </FormControl>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

export default withStyles(useStyles)(osaPattern)
