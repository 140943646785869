import React from 'react'
import { ThemeProvider, Container, Box } from '@mui/material'
import './style.css'
import CssBaseline from '@mui/material/CssBaseline'
import { useSelector } from 'react-redux'

// Styling
import useStyles from './style'
import { Routes, Route } from 'react-router-dom'
import HomePage from './HomePage'
import { LOGOUT_ENDPOINT } from '../env'
import Theme from '../lib/themeProvider'
import { withStyles } from '@mui/styles'
import AppTitle from '../components/AppTitle'
import OSBPage from './OSBPage'
import RepairPage from './RepairPage'
import OverviewPage from './OverviewPage'
import OVACPage from './OVACPage'

/**
 * Logout function
 *
 * @return null
 */
function Logout () {
    window.location.replace(LOGOUT_ENDPOINT)
    return null
}

// eslint-disable-next-line no-unused-vars
const App = ({ classes }) => {
    const other = useSelector((state) => state.other)
    const theme = Theme.theme(other.backend)

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Container >
                <Box>
                    <AppTitle title={'Bestelmodule'} />
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/osb" element={<OSBPage />} />
                        <Route path="/ovac" element={<OVACPage />} />
                        <Route path="/repair" element={<RepairPage />} />
                        <Route path="/logout" element={<Logout/>}/>
                        <Route path="/overview" element={<OverviewPage/>}/>
                    </Routes>
                </Box>
            </Container>
        </ThemeProvider>
    )
}

export default withStyles(useStyles)(App)
