import translations from './translations.json'
import { createRoot } from "react-dom/client";
import { flushSync } from "react-dom";
import React from 'react'
// eslint-disable-next-line import/no-unresolved
import renderFormStyle from './renderForm.css?inline'
import moment from "moment";

/**
 * Names of properties which will be excluded
 * @type {string[]}
 */
const excludedProperties = [
    'orderType',
    'initials',
    'birthdate',
    'id',
    'entryDate',
    'activeLasts'
]

/**
 * Property name suffixes which will be excluded
 * @type {string[]}
 */
const excludedPropertySuffixes = ["Accordion", "Errors"];
/**
 * Values of properties which will be excluded
 * @type {any[]}
 */
const excludedValues = [ '', '-', false, null ]

/**
 * Checks whether a name-value pair should be excluded from the document
 * @param name Name of the property
 * @param value Value of the property
 * @returns {boolean} True when it should be excluded
 */
const shouldBeExcluded = (name, value) => excludedPropertySuffixes.some(suffix => name.endsWith(suffix))
    || excludedProperties.includes(name)
    || excludedValues.includes(value)

/**
 * Formats a Number as a price, including currency sign and 2 decimal precision
 * @example
 * ```js
 * // € 1,23
 * console.log(renderPrice(1.2345))
 * ```
 * @param {Number} value
 * @returns {string}
 */
function renderPrice(value) {
    if(isNaN(parseFloat(value))) return value
    else return "€ " + parseFloat(value).toFixed(2).replace(".", ',');
}

/**
 * Maps property names to functions that simplify a value
 * @type {Object}
 */
const propertySimplifiers = {
    'receiveLocation': (value) => value.title,
    'fittingLocation': (value) => value.title,
    'therapist': (value) => value.name,
    'institutionCosts': renderPrice,
    'insuranceCosts': renderPrice,
    'customerCosts': renderPrice
}

/**
 * Simplifies an object to a value based on rules defined in {@link propertySimplifiers}
 *
 * @param name The name of the property
 * @param value The value of the property
 * @see propertySimplifiers
 * @returns {*} The simplified value
 */
function simplifyProperties(name, value) {
    if (name in propertySimplifiers) {
        return propertySimplifiers[name](value)
    } else {
        return value
    }
}

/**
 * @typedef ObjectEntries
 * @type {[string, unknown][]}
 */

/**
 * Creates a new object with all object property values removed
 *
 * @param {Object} data
 * @return {ObjectEntries}
 */
function filterSimpleProperties(data) {
    return Object.entries(data)
        .map(([name, value]) => [name, simplifyProperties(name, value)])
        .filter(([name, value]) => !shouldBeExcluded(name, value)
            && (Array.isArray(value) || moment.isMoment(value) || typeof value !== 'object'))
}

/**
 * @typedef LeftRightEntry
 * @type {Object}
 * @property {string} name Name of the property
 * @property {Object} left The properties on the left side
 * @property {Object} right The properties on the right side
 */

/**
 * Given an object `data`, copy the `left` and `right` properties and make sure that for every key in `left`, there is
 * also a key in `right` and vice versa. Missing keys will be assigned the value `undefined`
 *
 * @example
 * ```js
 * let obj = {
 *   left:  { a: 1, c: 3 },
 *   right: { a: 4, b: 5 }
 * }
 * let result = filterLeftRightProperties(obj)
 * // {
 * //   left:  { a: 1, b: undefined, c: 3         },
 * //   right: { a: 4, b: 5,         c: undefined }
 * // }
 * ```
 *
 * @param {Object} data
 *
 * @returns {LeftRightEntry[]} A copy of the object with only the left and right keys
 */
function filterLeftRightProperties(data) {

    const leftEntries = Object.entries(data.left ?? {})
        .filter(([name, value]) => !shouldBeExcluded(name, value))
    const rightEntries = Object.entries(data.right ?? {})
        .filter(([name, value]) => !shouldBeExcluded(name, value))

    let left  = Object.fromEntries(leftEntries)
    let right = Object.fromEntries(rightEntries)

    const leftKeys  = Object.keys(left)
    const rightKeys = Object.keys(right)

    let keys = new Set(leftKeys.concat(rightKeys))

    return keys.values()
        .map((key) => {
            return {
                name: key,
                left: left[key],
                right: right[key]
            }
        }).toArray()
}

/**
 * Renders the value of a property, while applying some formatting to make it human-readable
 * @param {Object} props
 * @param {any} props.value The value to render
 * @constructor
 */
const SimplePropertyValue = ({value}) => {
    let formattedValue = null;

    if (typeof value === 'string') {
        formattedValue = value;
    } else if (typeof value === 'number') {
        formattedValue = value.toString()
    } else if (typeof value === 'boolean') {
        formattedValue = value ? 'Ja' : 'Nee'
    } else if (moment.isMoment(value)) {
        formattedValue = moment(value).format('DD-MM-YYYY')
    } else {
        formattedValue = JSON.stringify(value, null, 2)
    }
    return (<>{formattedValue}</>)
}

/**
 * Displays property as a simple term and definition
 *
 * @param {Object} props
 * @param {string} props.name The name of the property
 * @param {string?} props.displayName The name to display in the UI
 * @param {any} props.value The value to display
 * @returns {Element}
 * @constructor
 */
const SimpleProperty = ({name, displayName, value}) => (
    <div className={`property-${name}`}>
        <dt>{displayName ?? name}</dt>
        <dd>
            <SimplePropertyValue value={value} />
        </dd>
    </div>
)

/**
 * Creates a table in the form of
 *
 * | `title`   | Left  | Right |
 * |-----------|-------|-------|
 * | Prop #1   | value | value |
 * | Prop #2   | value | value |
 *
 * @param {Object} props
 * @param {string} title The title for this table
 * @param {LeftRightEntry[]} props.data The data for in the table
 * @constructor
 */
const LeftRightTable = ({data, title}) => {
    if (data && data.length > 0) {
        return (
            <table>
                <thead>
                    <tr>
                        <th><h2>{title}</h2></th>
                        <th>{translations['left']}</th>
                        <th>{translations['right']}</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(row => (
                        <tr key={row.name}>
                            <th>{translations[row.name] ?? row.name}</th>
                            <td><SimplePropertyValue name={row.name} value={row.left} /></td>
                            <td><SimplePropertyValue name={row.name} value={row.right}/></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        )
    }
}

/**
 * @param {Object} props
 * @param {ObjectEntries} props.data
 * @constructor
 */
const SimplePropertyList = ({data}) => {
    return (
        <dl>
            { data.map(([name, value]) => (
                <SimpleProperty
                    key={name}
                    name={name}
                    displayName={translations[name]}
                    value={value} />
            )) }
        </dl>
    )
}

/**
 * Renders the application to a React fragment
 * @param {Object} props
 * @param {Object} props.formData The state of the application
 * @return {React.Fragment} The rendered form
 */
function FormRenderer({formData}) {
    let fileName = formData['fileName'];
    let extensionDotIndex = fileName.lastIndexOf('.');
    fileName = fileName.substring(0, extensionDotIndex)

    let formTitle = formData['orderType']
    let simpleProperties = filterSimpleProperties(formData)
    let simplePropertyKeys = new Set(simpleProperties.map(prop => prop[0]))
    let complicatedProperties = Object.entries(formData)
        .filter(([name, value]) => !shouldBeExcluded(name, value) && !simplePropertyKeys.has(name))

    return (
        <>
            <head>
                <script>{'const formData = ' + JSON.stringify(formData, null, 2)}</script>
                <style>{ `:root { --document-title: '${fileName}'; }` + renderFormStyle}</style>
            </head>
            <body>
                <header>
                    <h1>{formTitle} <span className='title-suffix'>bestelformulier</span> </h1>
                </header>
                <SimplePropertyList data={simpleProperties} />
                {
                    complicatedProperties.map(([name, value]) => {
                        const simpleProperties = filterSimpleProperties(value);
                        const leftRightProperties = filterLeftRightProperties(value)
                        const hasLeftRightProperties = leftRightProperties && leftRightProperties.length > 0

                        return (
                            <section key={name}>
                                {hasLeftRightProperties && <LeftRightTable
                                    title={translations[name] ?? name}
                                    data={leftRightProperties} /> }
                                {!hasLeftRightProperties && (<header>
                                    <h2> { translations[name] ?? name } </h2>
                                </header>)}
                                {simpleProperties && (<SimplePropertyList data={simpleProperties} />)}
                            </section>
                        )
                    })
                }
            </body>
        </>
    )
}

/**
 * Renders the application state to an HTML page suited for human consumption.
 *
 * This function makes the following assumptions:
 *
 *  1. The state object is 2 levels deep, or 3 when describing properties for the left and right side
 *  2. The keys of the first levels will map to headers, unless it's a simple value (meaning, not an object)
 *  3. If the second level object contains either the key "left" or "right", a table will be generated for each key
 *     in the "left" and the "right" object
 *  4. This function will translate all object keys with values found in `translations.json` or fall back to its key
 *     if not found.
 *
 * Some more remarks:
 *  1. The value of `data.orderType` will be used for the title of the form
 *  2. The value of `data.fileName` will be printed as document title (with the json extension stripped)
 *  3. If the property name is present in {@link propertySimplifiers}, that function will be called to format the value
 *  4. Properties in {@link excludedProperties} will not be printed
 *  5. Properties with values listed in {@link excludedValues} will be treated as they were not defined
 *  6. Properites with object values after calling {@link propertySimplifiers} will be ignored.
 *
 * @param {Object} data The form data
 * @return {HTMLElement} The produced HTML element
 */
export default function(data) {
    const wrapper = document.createElement('html')
    const root = createRoot(wrapper)
    flushSync(() => root.render( <FormRenderer formData={data} /> ))
    return wrapper
}