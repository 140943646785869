import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './initialState'

const otherSlice = createSlice({
    name: 'other',
    initialState,
    reducers: {
        changeVoetmax (state, action) {
            state.voetmax = action.payload
        },
        changeBackend (state, action) {
            state.backend = action.payload
        },
        changeAllValid (state, action) {
            state.allValid = action.payload
        },
        changeErrorDialog (state, action) {
            state.errorDialog = action.payload
        },
        setPreviousOrders (state, action) {
            state.previousOrders = action.payload
        },
        setBreakTheGlass (state, action) {
            state.breakTheGlass = action.payload
        }
    }
})

export const { changeVoetmax, changeBackend, setPreviousOrders, changeAllValid, changeErrorDialog, setBreakTheGlass } = otherSlice.actions
export default otherSlice.reducer
