export const taskType = [
    'OVAC',
    'OSB',
    'Losse steunzool',
    'Veiligheidsschoen'
]

export const developMethod = [
    '1/1',
    '1/2',
    '3/4'
]

export const insoleType = [
    'Scan',
    'EVA',
    'Op gips',
    'Op leest',
    'Sport',
    'Birko',
    'Hoffa',
    'PLT'
]

export const soleSpecification = [
    'Uitvoering',
    'Gewoon',
    'Kuip',
    'Keerwand mediaal',
    'Keerwand lateraal'
]

export const soleMaterial = [
    'Thermokurk',
    'Kurk',
    'Podoform',
    'MO',
    'Ercoflex',
    'Poro'
]

export const heelSpur = [
    '-',
    'Links',
    'Rechts',
    'Beide'
]

export const heelLift = [
    '-',
    '3mm',
    '4mm',
    '5mm'
]

export const stiffness = [
    'n.v.t.',
    '30',
    '40',
    '50',
    '30/50'
]

export const correction = [
    'Geheel proneren',
    'Geheel supineren',
    'Voor supineren',
    'Achter supineren',
    'Voor proneren',
    'Achter proneren',
    'Mediaal ophogen',
    'Torsie',
    'Detorsie',
    'STTS',
    '-'
]

export const correctionHeight = [
    '3mm',
    '4mm',
    '5mm'
]

export const transverseSupport = [
    '3mm',
    '5mm'
]

export const transverseSupportMaterial = [
    '-',
    'Rubberkurk',
    'Flexor',
    'Groen',
    'Wim Majoor',
    'Multiform'
]

export const pelot = [
    'Laten staan',
    'Weg schuren',
    'Zacht klein',
    'Zacht middel',
    'Zacht groot',
    'T-pelot'
]

export const toeBar = [
    '-',
    '3mm',
    '4mm',
    '5mm'
]

export const toeBarMaterial = [
    'PPT',
    'Multiform'
]

export const methods = [
    'Marilon',
    'PPT',
    'Zwartschuim',
    'Sandwich'
]

export const coverMaterial = [
    'On steam beige',
    'On steam zwart',
    'Leer Wittepoel',
    'Diafoam blauw',
    'Diafoam zwart',
    'Anti-statisch',
    'Anders'
]

export const methodHeelSize = { min: 0 }
export const methodFrontSize = { min: 0 }
export const methodFullSize = { min: 0 }
