import React from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary as MuiAccordionSummary,
    FormControl,
    FormGroup,
    Grid,
    Typography,
    styled,
    FormControlLabel,
    Checkbox,
    FormLabel,
    Box,
    TextField,
    Autocomplete
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { useSelector, useDispatch } from 'react-redux'
import { osaSupplementAccordion, osaSupplementData } from '../../store/reducers/osaSupplement'

import useStyles from './style'
import { withStyles } from '@mui/styles'

import dataProvider from '../../lib/dataProvider'
import { deepCopy } from '../../lib/util'

const data = dataProvider.osaSupplement

const osaSupplement = ({ classes }) => {
    // state management
    const dispatch = useDispatch()
    const osaSupplement = useSelector((state) => state.osaSupplement.osaSupplementData)
    const error = useSelector((state) => state.osaSupplement.osaSupplementErrors)
    const tab = useSelector((state) => state.osaSupplement.osaSupplementAccordion)
    const [expanded, setExpanded] = React.useState(['panel1'])

    /**
     * Handle tab change
     * @param {*} event - event
     * @param {*} newValue - selected tab
     */
    function handleChange (accordionValue) {
        const newExpanded = deepCopy(expanded)

        if (newExpanded.includes(accordionValue)) {
            newExpanded.splice(newExpanded.indexOf(accordionValue), 1)
        } else {
            newExpanded.push(accordionValue)
        }

        setExpanded(newExpanded)
    }

    /**
     * Toggle the accordion.
     */
    function toggleAccordion () {
        dispatch(osaSupplementAccordion({ key: 'accordion', value: !tab.accordion }))
    }

    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary
            expandIcon={<ExpandMore sx={{ fontSize: '0.9rem' }} />}
            {...props}
        />
    ))(({ theme }) => ({
        backgroundColor: 'rgba(0, 0, 0, .02)',
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(180deg)'
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1)
        }
    }))

    return (
        <Accordion defaultExpanded={true} expanded={tab.accordion}>
            <MuiAccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={toggleAccordion}
                className={!tab.accordion
                    ? tab.hasBeenOpened
                        ? tab.validate
                            ? classes.accordionSuccess
                            : classes.accordionError
                        : null
                    : null}
            >
                <Typography
                    className={!tab.accordion
                        ? tab.hasBeenOpened
                            ? tab.validate
                                ? classes.titleSuccess
                                : classes.titleError
                            : classes.title
                        : classes.title}>OSA Supplement</Typography>
            </MuiAccordionSummary>
            <AccordionDetails>
                <FormGroup>
                    <Accordion elevation={0} disableGutters expanded={expanded.includes('panel1')} onChange={(event) => handleChange('panel1')}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>Leest info</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={4}>
                                <Grid item xs={3}>
                                    <FormControl
                                        fullWidth
                                    >
                                        <FormControlLabel
                                            label="Bestaande leesten"
                                            control={
                                                <Checkbox
                                                    checked={osaSupplement.existingLast}
                                                    onChange={event => dispatch(osaSupplementData({ value: event.target.checked, key: 'existingLast' }))} />}

                                        />
                                    </FormControl>
                                </Grid>
                                {osaSupplement.existingLast && <Grid item xs={3}>
                                    <FormControl
                                        fullWidth
                                    >
                                        <FormControlLabel
                                            label="Aanpassen leesten"
                                            control={
                                                <Checkbox
                                                    checked={osaSupplement.updateLast}
                                                    onChange={event => dispatch(osaSupplementData({ value: event.target.checked, key: 'updateLast' }))} />}

                                        />
                                    </FormControl>
                                </Grid>}
                                <Grid item xs={2}>
                                    <FormControl
                                        fullWidth
                                    >
                                        <FormControlLabel
                                            label="PLT"
                                            control={
                                                <Checkbox
                                                    checked={osaSupplement.PLT}
                                                    onChange={event => dispatch(osaSupplementData({ value: event.target.checked, key: 'PLT' }))} />}

                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControl
                                        fullWidth
                                    >
                                        <FormControlLabel
                                            label="ELI"
                                            control={
                                                <Checkbox
                                                    checked={osaSupplement.ELI}
                                                    onChange={event => dispatch(osaSupplementData({ value: event.target.checked, key: 'ELI' }))} />}

                                        />
                                    </FormControl>
                                </Grid>
                                {osaSupplement.updateLast &&
                                    <Grid item xs={12}>
                                        <FormControl
                                            fullWidth
                                        >
                                            <FormLabel>Aanpassen leesten</FormLabel>
                                            <Box className={classes.autoComplete}></Box>
                                            <TextField
                                                type='text'
                                                value={osaSupplement.noteLasts}
                                                multiline
                                                rows={2}
                                                onChange={event => dispatch(osaSupplementData({ key: 'noteLasts', value: event.target.value }))} />
                                        </FormControl>
                                    </Grid>}

                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion elevation={0} disableGutters expanded={expanded.includes('panel2')} onChange={(event) => handleChange('panel2')}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>Supplementen</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={4}>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={3}>
                                    <FormControl
                                        fullWidth
                                    >
                                        <FormControlLabel
                                            label="Supplementen maker links"
                                            control={
                                                <Checkbox
                                                    checked={osaSupplement.left.supplementCreator}
                                                    onChange={event => dispatch(osaSupplementData({ key: 'supplementCreator', side: 'left', value: event.target.checked }))} />}

                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={3}>
                                    <FormControl
                                        fullWidth
                                    >
                                        <FormControlLabel
                                            label="Supplementen maker rechts"
                                            control={
                                                <Checkbox
                                                    checked={osaSupplement.right.supplementCreator}
                                                    onChange={event => dispatch(osaSupplementData({ key: 'supplementCreator', side: 'right', value: event.target.checked }))} />}

                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}></Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={3}>
                                    <FormControl
                                        fullWidth
                                    >
                                        <FormControlLabel
                                            label="Uitvlakken op stand links"
                                            control={
                                                <Checkbox
                                                    checked={osaSupplement.left.level}
                                                    onChange={event => dispatch(osaSupplementData({ key: 'level', side: 'left', value: event.target.checked }))} />}

                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={3}>
                                    <FormControl
                                        fullWidth
                                    >
                                        <FormControlLabel
                                            label="Uitvlakken op stand rechts"
                                            control={
                                                <Checkbox
                                                    checked={osaSupplement.right.level}
                                                    onChange={event => dispatch(osaSupplementData({ key: 'level', side: 'right', value: event.target.checked }))} />}

                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}></Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={3}>
                                    <FormControl
                                        fullWidth
                                    >
                                        <FormControlLabel
                                            label="Mediaal geleng recht houden links"
                                            control={
                                                <Checkbox
                                                    checked={osaSupplement.left.medialLenghtStraight}
                                                    onChange={event => dispatch(osaSupplementData({ key: 'medialLenghtStraight', side: 'left', value: event.target.checked }))} />}

                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={3}>
                                    <FormControl
                                        fullWidth
                                    >
                                        <FormControlLabel
                                            label="Mediaal geleng recht houden rechts"
                                            control={
                                                <Checkbox
                                                    checked={osaSupplement.right.medialLenghtStraight}
                                                    onChange={event => dispatch(osaSupplementData({ key: 'medialLenghtStraight', side: 'right', value: event.target.checked }))} />}

                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}></Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.heelHeight}
                                    >
                                        <FormLabel>Hakhoogte links (mm)</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder={data.heelHeight.min + ' - ' + data.heelHeight.max}
                                            value={osaSupplement.left.heelHeight}
                                            error={error.left.heelHeight}
                                            onChange={event => dispatch(osaSupplementData({ key: 'heelHeight', side: 'left', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.heelHeight}
                                    >
                                        <FormLabel>Hakhoogte rechts (mm)</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder={data.heelHeight.min + ' - ' + data.heelHeight.max}
                                            value={osaSupplement.right.heelHeight}
                                            error={error.right.heelHeight}
                                            onChange={event => dispatch(osaSupplementData({ key: 'heelHeight', side: 'right', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.version}
                                    >
                                        <FormLabel>Uitvoering links</FormLabel>
                                        <Autocomplete
                                            onChange={(event, value) => dispatch(osaSupplementData({ key: 'version', side: 'left', value }))}
                                            options={data.version}
                                            value={osaSupplement.left.version}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.left.version}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.version}
                                    >
                                        <FormLabel>Uitvoering rechts</FormLabel>
                                        <Autocomplete
                                            onChange={(event, value) => dispatch(osaSupplementData({ key: 'version', side: 'right', value }))}
                                            options={data.version}
                                            value={osaSupplement.right.version}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.right.version}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.heelThickness}
                                    >
                                        <FormLabel>Dikte hiel links (mm)</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={osaSupplement.left.heelThickness}
                                            error={error.left.heelThickness}
                                            onChange={event => dispatch(osaSupplementData({ key: 'heelThickness', side: 'left', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.heelThickness}
                                    >
                                        <FormLabel>Dikte hiel rechts (mm)</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={osaSupplement.right.heelThickness}
                                            error={error.right.heelThickness}
                                            onChange={event => dispatch(osaSupplementData({ key: 'heelThickness', side: 'right', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.toeThickness}
                                    >
                                        <FormLabel>Dikte teen links (mm)</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={osaSupplement.left.toeThickness}
                                            error={error.left.toeThickness}
                                            onChange={event => dispatch(osaSupplementData({ key: 'toeThickness', side: 'left', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.toeThickness}
                                    >
                                        <FormLabel>Dikte teen rechts (mm)</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={osaSupplement.right.toeThickness}
                                            error={error.right.toeThickness}
                                            onChange={event => dispatch(osaSupplementData({ key: 'toeThickness', side: 'right', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.waist}
                                    >
                                        <FormLabel>Teensprong (mm)</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={osaSupplement.left.waist}
                                            error={error.left.waist}
                                            onChange={event => dispatch(osaSupplementData({ key: 'waist', side: 'left', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.waist}
                                    >
                                        <FormLabel>Teensprong (mm)</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={osaSupplement.right.waist}
                                            error={error.right.waist}
                                            onChange={event => dispatch(osaSupplementData({ key: 'waist', side: 'right', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.ballThicknessMedial}
                                    >
                                        <FormLabel>Dikte bal mediaal links (mm)</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={osaSupplement.left.ballThicknessMedial}
                                            error={error.left.ballThicknessMedial}
                                            onChange={event => dispatch(osaSupplementData({ key: 'ballThicknessMedial', side: 'left', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.ballThicknessMedial}
                                    >
                                        <FormLabel>Dikte bal mediaal rechts (mm)</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={osaSupplement.right.ballThicknessMedial}
                                            error={error.right.ballThicknessMedial}
                                            onChange={event => dispatch(osaSupplementData({ key: 'ballThicknessMedial', side: 'right', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.ballThicknessLateral}
                                    >
                                        <FormLabel>Dikte bal lateraal links (mm)</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={osaSupplement.left.ballThicknessLateral}
                                            error={error.left.ballThicknessLateral}
                                            onChange={event => dispatch(osaSupplementData({ key: 'ballThicknessLateral', side: 'left', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.ballThicknessLateral}
                                    >
                                        <FormLabel>Dikte bal lateraal rechts (mm)</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={osaSupplement.right.ballThicknessLateral}
                                            error={error.right.ballThicknessLateral}
                                            onChange={event => dispatch(osaSupplementData({ key: 'ballThicknessLateral', side: 'right', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.material}
                                    >
                                        <FormLabel>Materiaal</FormLabel>
                                        <Autocomplete
                                            onChange={(event, value) => dispatch(osaSupplementData({ key: 'material', value }))}
                                            options={data.material}
                                            value={osaSupplement.material}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.material}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}></Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.rocker}
                                    >
                                        <FormLabel>Afwikkeling links</FormLabel>
                                        <Autocomplete
                                            onChange={(event, value) => dispatch(osaSupplementData({ key: 'rocker', side: 'left', value }))}
                                            options={data.rocker}
                                            value={osaSupplement.left.rocker}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.left.rocker}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.rocker}
                                    >
                                        <FormLabel>Afwikkeling rechts</FormLabel>
                                        <Autocomplete
                                            onChange={(event, value) => dispatch(osaSupplementData({ key: 'rocker', side: 'right', value }))}
                                            options={data.rocker}
                                            value={osaSupplement.right.rocker}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.right.rocker}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.schoring}
                                    >
                                        <FormLabel>Schoring links (mm)</FormLabel>
                                        <Autocomplete
                                            onChange={(event, value) => dispatch(osaSupplementData({ key: 'schoring', side: 'left', value }))}
                                            options={data.schoring}
                                            value={osaSupplement.left.schoring}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.left.schoring}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.schoring}
                                    >
                                        <FormLabel>Schoring rechts (mm)</FormLabel>
                                        <Autocomplete
                                            onChange={(event, value) => dispatch(osaSupplementData({ key: 'schoring', side: 'right', value }))}
                                            options={data.schoring}
                                            value={osaSupplement.right.schoring}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.right.schoring}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.secondSupplement}
                                    >
                                        <FormLabel>2e supplement links</FormLabel>
                                        <Autocomplete
                                            onChange={(event, value) => dispatch(osaSupplementData({ key: 'secondSupplement', side: 'left', value }))}
                                            options={data.secondSupplement}
                                            value={osaSupplement.left.secondSupplement}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.left.secondSupplement}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.secondSupplement}
                                    >
                                        <FormLabel>2e supplement rechts</FormLabel>
                                        <Autocomplete
                                            onChange={(event, value) => dispatch(osaSupplementData({ key: 'secondSupplement', side: 'right', value }))}
                                            options={data.secondSupplement}
                                            value={osaSupplement.right.secondSupplement}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.right.secondSupplement}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.fittingShoe}
                                    >
                                        <FormLabel>Passchoen links</FormLabel>
                                        <Autocomplete
                                            onChange={(event, value) => dispatch(osaSupplementData({ key: 'fittingShoe', side: 'left', value }))}
                                            options={data.fittingShoe}
                                            value={osaSupplement.left.fittingShoe}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.left.fittingShoe}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.fittingShoe}
                                    >
                                        <FormLabel>Passchoen rechts</FormLabel>
                                        <Autocomplete
                                            onChange={(event, value) => dispatch(osaSupplementData({ key: 'fittingShoe', side: 'right', value }))}
                                            options={data.fittingShoe}
                                            value={osaSupplement.right.fittingShoe}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.right.fittingShoe}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </FormGroup>
                <Grid container spacing={4}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}>
                        <FormControl
                            fullWidth
                        >
                            <FormLabel>Opmerking supplement</FormLabel>
                            <Box className={classes.autoComplete}></Box>
                            <TextField
                                type='text'
                                value={osaSupplement.note}
                                multiline
                                rows={3}
                                onChange={event => dispatch(osaSupplementData({ key: 'note', value: event.target.value }))} />
                        </FormControl>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

export default withStyles(useStyles)(osaSupplement)
