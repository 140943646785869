/**
 * Performs a deep copy on the object.
 * This preserves any non-object types
 *
 * @param {T} object the object to copy
 * @returns {T} the copy
 * @template T
 */
function deepCopy (object) {
    if (typeof object !== 'object') { return object }
    if (object === null) { return null }

    const out = Array.isArray(object) ? [] : {}
    for (const key in object) {
        out[key] = deepCopy(object[key])
    }
    return out
}

/**
 * Get prefix of environment
 *
 * @param {Object} backend - other.backend variable
 * @returns the given Prefix for that environment
 */
function getPrefix (backend) {
    switch (backend.key) {
    case 'Penders':
        return 'P'
    case 'Voetmax':
        return 'VM'
    case 'Kievit Orthopedie':
        return ''
    default:
        return ''
    }
}

/**
 * Convert the UK size to the EU size
 *
 * @param {Number} ukSize - the UK size
 * @return {Number} - the EU size
 */
function ukToEu (ukSize) {
    const initialSize = 1.27 * (ukSize + 23) + 2
    const euSize = Math.round(initialSize.toFixed(1) * 2, 1) / 2
    return euSize
}

/**
 * Convert the EU size to the UK size
 *
 * @param {Number} euSize - the EU size
 * @return {Number} - the UK size
 */
function euToUk (euSize) {
    const initialSize = (euSize - 2) / 1.27 - 23 // (1.27 * ukSize) + 32.51
    const ukSize = Math.round(initialSize.toFixed(1) * 2, 1) / 2
    return ukSize
}

/**
 *
 * @param {*} blob
 * @returns
 */
async function blobToBase64 (blob) {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = await function () {
        const base64data = reader.result
        console.log(base64data)
    }
}

/**
 * Given the Object `object` and a path in the form of "a.b.c", return
 * `object[a][b][c]`
 *
 * @param {Object} object The object to start with
 * @param {String} path The path in the form of "a.b.c"
 * @return {any}
 */
function getKeyByPath(object, path) {
    return path.split('.')
        .reduce((object, key) => object[key], object)
}

export { deepCopy, ukToEu, euToUk, getPrefix, blobToBase64, getKeyByPath }
