export const footwearType = [
    'Passchoen leer',
    'Voeringsschoen',
    'Tongsupplement',
    'Pantoffel',
    'Voering',
    'Folieschoen, ander formulier'
]

export const lining = [
    'Tot aan de bal',
    'Geheel'
]

export const liningMaterial = [
    'Standaard',
    'Antitransparant',
    'Foam',
    'Bont',
    'Durafit',
    'Voorbladvoering',
    'Biovoering (allergeenvrij)'
]

export const tonguePadMaterial = [
    '-',
    'PPT',
    'Lunairmed',
    'Multiform',
    'XRD'
]

export const tongueReinforcement = [
    '-',
    'Tepp',
    'Ercoflex 2 mm'
]

export const anklePadding = [
    'Geheel',
    'Alleen enkel',
    'Enkels extra',
    'Polsterkraag'
]

export const anklePaddingMaterial = [
    'PPT',
    'Lunairmed',
    'XRD'
]

export const contrefort = [
    'Normaal',
    'Hoog lateraal',
    'Hoog mediaal',
    'Hoog-lateraal+mediaal',
    'Rondom',
    'Rondom+U',
    'Peroneus',
    'Maur'
]

export const contrefortMaterial = [
    'Ercoflex',
    'Max',
    'Max + Thermite',
    'Corrit',
    'Renoflex',
    'Thermite',
    'Koolstof mediaal',
    'Leer',
    'M.O. Dun',
    'M.O. Dik',
    'Leer + Ercoflex',
    'Maur',
    'Multiform + Renoflex',
    'Ping pong'
]

export const schoring = [
    '5 lat',
    '10 lat',
    '15 lat',
    '20 lat',
    '25 lat',
    '30 lat',
    '5 med',
    '10 med',
    '15 med',
    '20 med',
    '25 med',
    '30 med'
]

export const nose = [
    'Normaal',
    'Uitgehold'
]
export const noseMaterial = [
    '-',
    'Leer',
    'Kunststof',
    'Diabetisch'
]

export const heelBuffer = [
    '-',
    'Links',
    'Rechts',
    'Beide'
]

export const entryPoint = [
    'Standaard',
    '10 mm naar voren',
    '20 mm naar voren',
    '30 mm naar voren',
    'Amerikaanse Derby',
    'Lijn op de leest'
]

export const soleSpace = [
    '-',
    '3mm',
    '5mm'
]

export const soleStiffening = [
    'Carbon dik',
    'Carbon dun',
    'Carbon gelamineerd',
    'MO'
]

export const closingMechanism = [
    'Haakband',
    'Haken en ringen',
    'Riem',
    'Rits',
    'Klittenband'
]

export const transverseSupport = [
    '-',
    'Rubberkurk',
    'Flexor',
    'Groen',
    'Wim Majoor',
    'Multiform'
]

export const pelot = [
    'Klein',
    'Middel',
    'Groot',
    'T-pelot'
]

export const toeTreshold = [
    '-',
    '3mm',
    '5mm'
]

export const toeTresholdMaterial = [
    'Multiform',
    'Rubberkurk'
]

export const heelCurb = [
    '-',
    '3mm',
    '5mm'
]
export const heelCurbMaterial = [
    'Multiform',
    'Rubberkurk'
]

export const insideSole = [
    'Leer',
    'Kunststof'
]

export const toes = [
    'PPT',
    'Schuimrubber',
    'Sandwich',
    'Siliconen',
    'Plastazote 3 mm',
    'Diafoam zwart',
    'Diafoam blauw',
    'Rode PPT'
]

export const front = [
    'PPT',
    'Schuimrubber',
    'Sandwich',
    'Siliconen',
    'Plastazote 3 mm',
    'Diafoam zwart',
    'Diafoam blauw',
    'Rode PPT'
]

export const heel = [
    'PPT',
    'Schuimrubber',
    'Sandwich',
    'Siliconen',
    'Plastazote 3 mm',
    'Diafoam zwart',
    'Diafoam blauw',
    'Rode PPT'
]

export const complete = [
    'PPT',
    'Schuimrubber',
    'Sandwich',
    'Siliconen',
    'Plastazote 3 mm',
    'Diafoam zwart',
    'Diafoam blauw',
    'Rode PPT'
]

export const finishingSoleStiffening = [
    'Carbon dik',
    'Carbon dun',
    'MO',
    'Zie aftekening supplement'
]

export const coverMaterial = [
    'Standaard',
    'Anti-transparant',
    'Plastazote 3 mm',
    'Diafoam zwart',
    'Diafoam blauw',
    'Sandwich',
    'Zacht leer',
    'Geit',
    'Witte damesvoering'
]

export const heelType = [
    'Blokhak',
    'Sleehak',
    'Anders (zie opmerking)'
]

export const heelSchoring = [
    '5 lat',
    '10 lat',
    '15 lat',
    '20 lat',
    '5 med',
    '10 med',
    '15 med',
    '20 med'
]

export const upperHeight = {
    min: 80,
    max: 450
}

export const heelHeight = {
    min: 0,
    max: 50
}

export const heelRounding = { min: 0 }
export const soleElevationHeel = { min: 0 }
export const soleElevationBall = { min: 0 }
export const soleElevationToe = { min: 0 }
export const addedLength = { min: 0 }
