import { configureStore } from '@reduxjs/toolkit'
import generalSlice from './reducers/general'
import other from './reducers/other'
import osbSlice from './reducers/osb'
import osbOutsoleSlice from './reducers/osbOutsole'
import repairSlice from './reducers/repair'

const store = configureStore({
    reducer: {
        general: generalSlice,
        osbShoe: osbSlice,
        osbOutsole: osbOutsoleSlice,
        repair: repairSlice,
        other
    },
    // removes a 'non-serializable value was detected in the state' error when selecting a date
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
})

export default store
