{
  "name": "boot-form",
  "version": "1.0.0",
  "private": true,
  "license": "UNLICENSED",
  "dependencies": {
    "@emotion/react": "^11.11.1",
    "@emotion/styled": "^11.11.0",
    "@mui/icons-material": "^5.15.16",
    "@mui/lab": "^5.0.0-alpha.170",
    "@mui/material": "^5.15.16",
    "@mui/styles": "^5.15.16",
    "@mui/x-date-pickers": "^7.3.2",
    "@originjs/vite-plugin-commonjs": "^1.0.3",
    "@reduxjs/toolkit": "^1.9.7",
    "@vitejs/plugin-react": "^4.2.1",
    "axios": "^1.6.8",
    "esbuild": "^0.21.0",
    "esbuild-plugin-inline-image": "^0.0.9",
    "exceljs": "^4.4.0",
    "keycloak-js": "^24.0.3",
    "moment": "^2.29.4",
    "notistack": "^3.0.1",
    "react": "^18.3.1",
    "react-dom": "^18.3.1",
    "react-redux": "^8.1.3",
    "react-router-dom": "^6.23.0",
    "redux": "^5.0.1",
    "vite": "^5.2.11",
    "web-vitals": "^1.1.2"
  },
  "scripts": {
    "start": "vite --port=3001",
    "dev": "vite dev --port=3001",
    "build": "vite build",
    "builder": "node build.js",
    "serve": "vite preview",
    "test": "jest --verbose --silent=false",
    "testWatch": "jest --watchAll --verbose --silent=false",
    "eject": "react-scripts eject"
  },
  "browserslist": {
    "production": [
      ">0.2%",
      "not dead",
      "not op_mini all"
    ],
    "development": [
      "last 1 chrome version",
      "last 1 firefox version",
      "last 1 safari version"
    ]
  },
  "devDependencies": {
    "@eslint/compat": "^1.2.4",
    "@eslint/eslintrc": "^3.2.0",
    "@eslint/js": "^9.17.0",
    "@nabla/vite-plugin-eslint": "^2.0.5",
    "@stylistic/eslint-plugin": "^2.12.1",
    "eslint": "^9.17.0",
    "eslint-plugin-import": "^2.31.0",
    "eslint-plugin-jsdoc": "^50.6.1",
    "eslint-plugin-n": "^15.3.0",
    "eslint-plugin-promise": "^7.2.1",
    "eslint-plugin-react": "^7.37.2",
    "eslint-plugin-react-redux": "^4.2.0"
  },
  "packageManager": "yarn@1.22.22+sha512.a6b2f7906b721bba3d67d4aff083df04dad64c399707841b7acf00f6b133b7ac24255f2652fa22ae3534329dc6180534e98d17432037ff6fd140556e2bb3137e"
}
