import { createStyles } from '@mui/styles'

const styles = createStyles((theme) => ({
    dialogButton: {
        background: theme.palette.secondary.main,
        marginRight: 'auto',
        marginLeft: 'unset',
        color: '#ffffff',
        fontWeight: 'bold',
        boxShadow: '0 0px 0px 0px rgba(255, 255, 255, 0)',
        '&:hover': {
            boxShadow: '0 0px 0px 0px rgba(255, 255, 255, 0)'
        }
    },
    dialogContinueButton: {
        background: theme.palette.error.main,
        marginLeft: '0',
        marginRight: 'unset'
    }
}))

export default styles
