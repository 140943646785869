import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material'
import useStyles from './style'
import { withStyles } from '@mui/styles'
import { LoadingButton } from '@mui/lab'
import { Receipt } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { changeAllValid, changeErrorDialog } from '../../store/reducers/other'
import { closeGeneral, patientData } from '../../store/reducers/general'
import { repairClose } from '../../store/reducers/repair'
import { osbOutsoleClose } from '../../store/reducers/osbOutsole'
import { osbShoeClose } from '../../store/reducers/osb'
import moment from 'moment'
import api from '../../lib/api'

const NavigationButtons = ({ classes }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const orderType = useSelector((state) => state.general.orderType)
    const general = useSelector((state) => state.general.generalAccordion)
    const patient = useSelector((state) => state.general.patientData)
    const repair = useSelector((state) => state.repair.repairAccordion)
    const osb = useSelector((state) => state.osbShoe.osbShoeAccordion)
    const osbOutsole = useSelector((state) => state.osbOutsole.osbOutsoleAccordion)

    useEffect(() => {
        if (!loading) return
        let isEverythingValid = false
        switch (orderType) {
        case 'OSB':
            isEverythingValid =
            general.validate &&
            osb.validate &&
            osbOutsole.validate
            break
        case 'OVAC':
            isEverythingValid =
            general.validate &&
            osbOutsole.validate
            break
        case 'Reparatie':
            isEverythingValid =
            general.validate &&
            repair.validate
            break
        }

        setLoading(false)
        dispatch(changeAllValid(isEverythingValid))
        dispatch(changeErrorDialog(!isEverythingValid))
    }, [general, osb, osbOutsole, repair])
    /**
     * Close all tabs
     */
    function closeTabs () {
        setLoading(true)
        if (patient.id === '' && patient.number !== '') {
            api.patients(patient.number,
                (data) => {
                    const bday = moment(data.birthDate).add(2, 'h')
                    const lasts = [{ formattedName: '-' }]
                    data.lasts.forEach(element => {
                        if (element.removed === null) {
                            lasts.push(element)
                        }
                    })
                    dispatch(patientData({ key: 'number', value: patient.number }))
                    dispatch(patientData({ key: 'lastName', value: data.lastName }))
                    dispatch(patientData({ key: 'birthdate', value: bday }))
                    dispatch(patientData({ key: 'gender', value: data.sex.toLowerCase() }))
                    dispatch(patientData({ key: 'id', value: `${data.id}` }))
                    dispatch(patientData({ key: 'initials', value: `${data.initials}` }))
                    dispatch(patientData({ key: 'activeLasts', value: lasts }))
                    if (lasts.length === 2) {
                        dispatch(patientData({ key: 'selectedLast', value: lasts[1].formattedName }))
                    }
                })
        }
        dispatch(changeAllValid(false))
        dispatch(changeErrorDialog(false))
        dispatch(closeGeneral())
        switch (orderType) {
        case 'OSB':
            dispatch(osbShoeClose())
            dispatch(osbOutsoleClose())
            break
        case 'OVAC':
            dispatch(osbOutsoleClose())
            break
        case 'Reparatie':
            dispatch(repairClose())
            break
        }
    }

    return (
        <div className={classes.container}>
            <Button
                variant="contained"
                color='warning'
                className={classes.backButton}
                onClick={() => window.location.replace('/')}
            >Terug</Button>
            <LoadingButton
                loading={loading}
                variant="contained"
                loadingPosition="start"
                color='secondary'
                onClick={() => closeTabs()}
                startIcon={<Receipt />}
            >
                Naar bestelgegevens
            </LoadingButton>
        </div>
    )
}

export default withStyles(useStyles)(NavigationButtons)
