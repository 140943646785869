export const initialState = {
    osbOutsoleData: {
        heelAdjustment: '',
        note: '',
        ownedShoeBrand: '',
        ownedShoeSize: '',
        ownedShoeColor: '',
        left: {
            rocker: '',
            heel: '',
            ball: '',
            toe: '',
            correction: '',
            correctionHeight: '',
            waist: '',
            lateral: '',
            medial: '',
            soleStiffening: '',
            otherSole: '',
            stretch: '',
            solePad: '',
            closureAdjust: '',
            tongueVelcro: false,
            replaceAdhesive: false,
            extendVelcro: false,
            shortenVelcro: false,
            openPosition: '',
            lowerUpper: ''
        },
        right: {
            rocker: '',
            heel: '',
            ball: '',
            toe: '',
            correction: '',
            correctionHeight: '',
            waist: '',
            lateral: '',
            medial: '',
            soleStiffening: '',
            otherSole: '',
            stretch: '',
            solePad: '',
            closureAdjust: '',
            tongueVelcro: false,
            replaceAdhesive: false,
            extendVelcro: false,
            shortenVelcro: false,
            openPosition: '',
            lowerUpper: ''
        }
    },
    osbOutsoleAccordion: {
        accordion: false,
        validate: false,
        hasBeenOpened: false
    },
    osbOutsoleErrors: {
        message: [],
        heelAdjustment: false,
        left: {
            rocker: false,
            heel: false,
            ball: false,
            toe: false,
            correction: false,
            correctionHeight: false,
            waist: false,
            lateral: false,
            medial: false,
            soleStiffening: false,
            otherSole: false,
            stretch: false,
            solePad: false,
            closureAdjust: false,
            tongueVelcro: false,
            replaceAdhesive: false,
            extendVelcro: false,
            shortenVelcro: false,
            openPosition: false,
            lowerUpper: false
        },
        right: {
            rocker: false,
            heel: false,
            ball: false,
            toe: false,
            correction: false,
            correctionHeight: false,
            waist: false,
            lateral: false,
            medial: false,
            soleStiffening: false,
            otherSole: false,
            stretch: false,
            solePad: false,
            closureAdjust: false,
            tongueVelcro: false,
            replaceAdhesive: false,
            extendVelcro: false,
            shortenVelcro: false,
            openPosition: false,
            lowerUpper: false
        }
    }
}
