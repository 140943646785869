import React from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary as MuiAccordionSummary,
    FormControl,
    FormGroup,
    FormLabel,
    Grid,
    Typography,
    Autocomplete,
    TextField,
    styled,
    Box,
    FormControlLabel,
    Checkbox
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { useSelector, useDispatch } from 'react-redux'
import { osbOutsoleAccordion, osbOutsoleData } from '../../store/reducers/osbOutsole'

import useStyles from './style'
import { withStyles } from '@mui/styles'

import dataProvider from '../../lib/dataProvider'
import { deepCopy } from '../../lib/util'
import { osbShoeData } from '../../store/reducers/osb'

const data = dataProvider.osbOutsole

const OSBOutsole = ({ classes }) => {
    // state management
    const dispatch = useDispatch()
    const osbOutsole = useSelector((state) => state.osbOutsole.osbOutsoleData)
    const error = useSelector((state) => state.osbOutsole.osbOutsoleErrors)
    const tab = useSelector((state) => state.osbOutsole.osbOutsoleAccordion)

    const osb = useSelector((state) => state.osbShoe.osbShoeData)
    const orderType = useSelector((state) => state.general.orderType)
    const [expanded, setExpanded] = React.useState(['panel1'])
    // const other = useSelector((state) => state.other)

    /**
     * Toggle the accordion.
     */
    function toggleAccordion () {
        dispatch(osbOutsoleAccordion({ key: 'accordion', value: !tab.accordion }))
    }

    /**
     * Handle tab change
     * @param {*} event - event
     * @param {*} newValue - selected tab
     */
    function handleChange (accordionValue) {
        const newExpanded = deepCopy(expanded)

        if (newExpanded.includes(accordionValue)) {
            newExpanded.splice(newExpanded.indexOf(accordionValue), 1)
        } else {
            newExpanded.push(accordionValue)
        }

        setExpanded(newExpanded)
    }

    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary
            expandIcon={<ExpandMore sx={{ fontSize: '0.9rem' }} />}
            {...props}
        />
    ))(({ theme }) => ({
        backgroundColor: 'rgba(0, 0, 0, .02)',
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(180deg)'
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1)
        }
    }))

    /**
     * Set send to podoIt true or false.
     *
     * @param {Boolean} event - bool
     */
    function setCheckedInsole (event) {
        dispatch(osbShoeData({ key: 'insole', value: event.target.checked }))
    };

    return (
        <Accordion defaultExpanded={true} expanded={tab.accordion}>
            <MuiAccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={toggleAccordion}
                className={!tab.accordion
                    ? tab.hasBeenOpened
                        ? tab.validate
                            ? classes.accordionSuccess
                            : classes.accordionError
                        : null
                    : null}
            >
                <Typography
                    className={!tab.accordion
                        ? tab.hasBeenOpened
                            ? tab.validate
                                ? classes.titleSuccess
                                : classes.titleError
                            : classes.title
                        : classes.title}>Aanpassingen onderwerk/ schacht</Typography>
            </MuiAccordionSummary>
            <AccordionDetails>
                <Grid container spacing={4}>
                    {orderType === 'OVAC' && <Grid item xs={4}>
                        <FormControl
                            fullWidth
                            error={error.left.ownedShoeBrand}
                        >
                            <FormLabel>Merk schoen</FormLabel>
                            <Box className={classes.autoComplete}></Box>
                            <TextField
                                value={osbOutsole.ownedShoeBrand}
                                error={error.ownedShoeBrand}
                                onChange={event => dispatch(osbOutsoleData({ key: 'ownedShoeBrand', value: event.target.value }))}
                            />
                        </FormControl>
                    </Grid>}
                    {orderType === 'OVAC' && <Grid item xs={4}>
                        <FormControl
                            fullWidth
                            error={error.ownedShoeSize}
                        >
                            <FormLabel>Maat</FormLabel>
                            <Box className={classes.autoComplete}></Box>
                            <TextField
                                value={osbOutsole.ownedShoeSize}
                                error={error.ownedShoeSize}
                                onChange={event => dispatch(osbOutsoleData({ key: 'ownedShoeSize', value: event.target.value }))}
                            />
                        </FormControl>
                    </Grid>}
                    {orderType === 'OVAC' && <Grid item xs={4}>
                        <FormControl
                            fullWidth
                            error={error.ownedShoeColor}
                        >
                            <FormLabel>Kleur</FormLabel>
                            <Box className={classes.autoComplete}></Box>
                            <TextField
                                value={osbOutsole.ownedShoeColor}
                                error={error.ownedShoeColor}
                                onChange={event => dispatch(osbOutsoleData({ key: 'ownedShoeColor', value: event.target.value }))}
                            />
                        </FormControl>
                    </Grid>}
                    {orderType === 'OVAC' && <Grid item xs={12}></Grid>}
                </Grid>
                <FormGroup>
                    <Accordion elevation={0} disableGutters expanded={expanded.includes('panel1')} onChange={(event) => handleChange('panel1')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMore />}>
                            <Typography>Schacht</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.stretch}
                                    >
                                        <FormLabel>Oprekken links</FormLabel>
                                        <Autocomplete
                                            value={osbOutsole.left.stretch}
                                            onChange={(event, value) => dispatch(osbOutsoleData({ key: 'stretch', side: 'left', value }))}
                                            options={data.stretch}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.left.stretch}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.stretch}
                                    >
                                        <FormLabel>Oprekken rechts</FormLabel>
                                        <Autocomplete
                                            value={osbOutsole.right.stretch}
                                            onChange={(event, value) => dispatch(osbOutsoleData({ key: 'stretch', side: 'right', value }))}
                                            options={data.stretch}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.right.stretch}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.solePad}
                                    >
                                        <FormLabel>Tongpolster links</FormLabel>
                                        <Autocomplete
                                            value={osbOutsole.left.solePad}
                                            onChange={(event, value) => dispatch(osbOutsoleData({ key: 'solePad', side: 'left', value }))}
                                            options={data.solePad}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.left.solePad}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.solePad}
                                    >
                                        <FormLabel>Tongpolster rechts</FormLabel>
                                        <Autocomplete
                                            value={osbOutsole.right.solePad}
                                            onChange={(event, value) => dispatch(osbOutsoleData({ key: 'solePad', side: 'right', value }))}
                                            options={data.solePad}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.right.solePad}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.closureAdjust}
                                    >
                                        <FormLabel>Sluiting aanpassen links</FormLabel>
                                        <Autocomplete
                                            value={osbOutsole.left.closureAdjust}
                                            onChange={(event, value) => dispatch(osbOutsoleData({ key: 'closureAdjust', side: 'left', value }))}
                                            options={data.closureAdjust}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.left.closureAdjust}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.closureAdjust}
                                    >
                                        <FormLabel>Sluiting aanpassen rechts</FormLabel>
                                        <Autocomplete
                                            value={osbOutsole.right.closureAdjust}
                                            onChange={(event, value) => dispatch(osbOutsoleData({ key: 'closureAdjust', side: 'right', value }))}
                                            options={data.closureAdjust}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.right.closureAdjust}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}></Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                    >
                                        <FormControlLabel
                                            label="Klittenband aan tong links"
                                            control={
                                                <Checkbox
                                                    checked={osbOutsole.left.tongueVelcro}
                                                    onChange={event => dispatch(osbOutsoleData({ value: event.target.checked, side: 'left', key: 'tongueVelcro' }))} />}

                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                    >
                                        <FormControlLabel
                                            label="Klittenband aan tong rechts"
                                            control={
                                                <Checkbox
                                                    checked={osbOutsole.right.tongueVelcro}
                                                    onChange={event => dispatch(osbOutsoleData({ value: event.target.checked, side: 'right', key: 'tongueVelcro' }))} />}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                    >
                                        <FormControlLabel
                                            label="Kleefband vervangen links"
                                            control={
                                                <Checkbox
                                                    checked={osbOutsole.left.replaceAdhesive}
                                                    onChange={event => dispatch(osbOutsoleData({ value: event.target.checked, side: 'left', key: 'replaceAdhesive' }))} />}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                    >
                                        <FormControlLabel
                                            label="Kleefband vervangen rechts"
                                            control={
                                                <Checkbox
                                                    checked={osbOutsole.right.replaceAdhesive}
                                                    onChange={event => dispatch(osbOutsoleData({ value: event.target.checked, side: 'right', key: 'replaceAdhesive' }))} />}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                    >
                                        <FormControlLabel
                                            label="Klittenband verlengen links"
                                            control={
                                                <Checkbox
                                                    checked={osbOutsole.left.extendVelcro}
                                                    onChange={event => dispatch(osbOutsoleData({ value: event.target.checked, side: 'left', key: 'extendVelcro' }))} />}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                    >
                                        <FormControlLabel
                                            label="Klittenband verlengen rechts"
                                            control={
                                                <Checkbox
                                                    checked={osbOutsole.right.extendVelcro}
                                                    onChange={event => dispatch(osbOutsoleData({ value: event.target.checked, side: 'right', key: 'extendVelcro' }))} />}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                    >
                                        <FormControlLabel
                                            label="Klittenband verkorten links"
                                            control={
                                                <Checkbox
                                                    checked={osbOutsole.left.shortenVelcro}
                                                    onChange={event => dispatch(osbOutsoleData({ value: event.target.checked, side: 'left', key: 'shortenVelcro' }))} />}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                    >
                                        <FormControlLabel
                                            label="Klittenband verkorten rechts"
                                            control={
                                                <Checkbox
                                                    checked={osbOutsole.right.shortenVelcro}
                                                    onChange={event => dispatch(osbOutsoleData({ value: event.target.checked, side: 'right', key: 'shortenVelcro' }))} />}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}></Grid>
                            </Grid>
                            <Grid container spacing={4}>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.openPosition}
                                    >
                                        <FormLabel>Openstand links (mm)</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={osbOutsole.left.openPosition}
                                            error={error.left.openPosition}
                                            onChange={event => dispatch(osbOutsoleData({ key: 'openPosition', side: 'left', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.openPosition}
                                    >
                                        <FormLabel>Openstand rechts (mm)</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={osbOutsole.right.openPosition}
                                            error={error.right.openPosition}
                                            onChange={event => dispatch(osbOutsoleData({ key: 'openPosition', side: 'right', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.lowerUpper}
                                    >
                                        <FormLabel>Schacht verlagen links (mm)</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={osbOutsole.left.lowerUpper}
                                            error={error.left.lowerUpper}
                                            onChange={event => dispatch(osbOutsoleData({ key: 'lowerUpper', side: 'left', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.lowerUpper}
                                    >
                                        <FormLabel>Schacht verlagen rechts (mm)</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={osbOutsole.right.lowerUpper}
                                            error={error.right.lowerUpper}
                                            onChange={event => dispatch(osbOutsoleData({ key: 'lowerUpper', side: 'right', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion elevation={0} disableGutters expanded={expanded.includes('panel2')} onChange={(event) => handleChange('panel2')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMore />}>
                            <Typography>Onderwerk</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.rocker}
                                    >
                                        <FormLabel>Afwikkelbalk links*</FormLabel>
                                        <Autocomplete
                                            value={osbOutsole.left.rocker}
                                            onChange={(event, value) => dispatch(osbOutsoleData({ key: 'rocker', side: 'left', value }))}
                                            options={data.rocker}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.left.rocker}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.rocker}
                                    >
                                        <FormLabel>Afwikkelbalk rechts*</FormLabel>
                                        <Autocomplete
                                            value={osbOutsole.right.rocker}
                                            onChange={(event, value) => dispatch(osbOutsoleData({ key: 'rocker', side: 'right', value }))}
                                            options={data.rocker}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.right.rocker}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.heel}
                                    >
                                        <FormLabel>Hak links (mm)</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={osbOutsole.left.heel}
                                            error={error.left.heel}
                                            onChange={event => dispatch(osbOutsoleData({ key: 'heel', side: 'left', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.heel}
                                    >
                                        <FormLabel>Hak rechts (mm)</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={osbOutsole.right.heel}
                                            error={error.right.heel}
                                            onChange={event => dispatch(osbOutsoleData({ key: 'heel', side: 'right', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.ball}
                                    >
                                        <FormLabel>Bal links (mm)</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={osbOutsole.left.ball}
                                            error={error.left.ball}
                                            onChange={event => dispatch(osbOutsoleData({ key: 'ball', side: 'left', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.ball}
                                    >
                                        <FormLabel>Bal rechts (mm)</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={osbOutsole.right.ball}
                                            error={error.right.ball}
                                            onChange={event => dispatch(osbOutsoleData({ key: 'ball', side: 'right', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.toe}
                                    >
                                        <FormLabel>Teen links (mm)</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={osbOutsole.left.toe}
                                            error={error.left.toe}
                                            onChange={event => dispatch(osbOutsoleData({ key: 'toe', side: 'left', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.toe}
                                    >
                                        <FormLabel>Teen rechts (mm)</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={osbOutsole.right.toe}
                                            error={error.right.toe}
                                            onChange={event => dispatch(osbOutsoleData({ key: 'toe', side: 'right', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.correction}
                                    >
                                        <FormLabel>Correctie links</FormLabel>
                                        <Autocomplete
                                            value={osbOutsole.left.correction}
                                            onChange={(event, value) => dispatch(osbOutsoleData({ key: 'correction', side: 'left', value }))}
                                            options={data.correction}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.left.correction}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.correctionHeight}
                                    >
                                        <FormLabel>Hoogte correctie links</FormLabel>
                                        <Autocomplete
                                            value={osbOutsole.left.correctionHeight}
                                            onChange={(event, value) => dispatch(osbOutsoleData({ key: 'correctionHeight', side: 'left', value }))}
                                            options={data.correctionHeight}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.left.correctionHeight}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={3}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.correction}
                                    >
                                        <FormLabel>Correctie rechts</FormLabel>
                                        <Autocomplete
                                            value={osbOutsole.right.correction}
                                            onChange={(event, value) => dispatch(osbOutsoleData({ key: 'correction', side: 'right', value }))}
                                            options={data.correction}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.right.correction}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.correctionHeight}
                                    >
                                        <FormLabel>Hoogte correctie rechts</FormLabel>
                                        <Autocomplete
                                            value={osbOutsole.right.correctionHeight}
                                            onChange={(event, value) => dispatch(osbOutsoleData({ key: 'correctionHeight', side: 'right', value }))}
                                            options={data.correctionHeight}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.right.correctionHeight}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.waist}
                                    >
                                        <FormLabel>Teensprong (mm)</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={osbOutsole.left.waist}
                                            error={error.left.waist}
                                            onChange={event => dispatch(osbOutsoleData({ key: 'waist', side: 'left', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.waist}
                                    >
                                        <FormLabel>Teensprong (mm)</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={osbOutsole.right.waist}
                                            error={error.right.waist}
                                            onChange={event => dispatch(osbOutsoleData({ key: 'waist', side: 'right', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                        error={error.heelAdjustment}
                                    >
                                        <FormLabel>Hak aanpassen</FormLabel>
                                        <Autocomplete
                                            value={osbOutsole.heelAdjustment}
                                            onChange={(event, value) => dispatch(osbOutsoleData({ key: 'heelAdjustment', value }))}
                                            options={data.heelAdjustment}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.heelAdjustment}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion elevation={0} disableGutters expanded={expanded.includes('panel3')} onChange={(event) => handleChange('panel3')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMore />}>
                            <Typography>Hakschoring</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.lateral}
                                    >
                                        <FormLabel>Hakschoring lateraal links</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={osbOutsole.left.lateral}
                                            error={error.left.lateral}
                                            onChange={event => dispatch(osbOutsoleData({ key: 'lateral', side: 'left', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.lateral}
                                    >
                                        <FormLabel>Hakschoring lateraal rechts</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={osbOutsole.right.lateral}
                                            error={error.right.lateral}
                                            onChange={event => dispatch(osbOutsoleData({ key: 'lateral', side: 'right', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.medial}
                                    >
                                        <FormLabel>Hakschoring mediaal links</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={osbOutsole.left.medial}
                                            error={error.left.medial}
                                            onChange={event => dispatch(osbOutsoleData({ key: 'medial', side: 'left', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.medial}
                                    >
                                        <FormLabel>Hakschoring mediaal rechts</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={osbOutsole.right.medial}
                                            error={error.right.medial}
                                            onChange={event => dispatch(osbOutsoleData({ key: 'medial', side: 'right', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion elevation={0} disableGutters expanded={expanded.includes('panel4')} onChange={(event) => handleChange('panel4')}>
                        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" expandIcon={<ExpandMore />}>
                            <Typography>Zoolverstijving</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.soleStiffening}
                                    >
                                        <FormLabel>Zoolverstijving links</FormLabel>
                                        <Autocomplete
                                            value={osbOutsole.left.soleStiffening}
                                            onChange={(event, value) => dispatch(osbOutsoleData({ key: 'soleStiffening', side: 'left', value }))}
                                            options={data.soleStiffening}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.left.soleStiffening}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.soleStiffening}
                                    >
                                        <FormLabel>Zoolverstijving rechts</FormLabel>
                                        <Autocomplete
                                            value={osbOutsole.right.soleStiffening}
                                            onChange={(event, value) => dispatch(osbOutsoleData({ key: 'soleStiffening', side: 'right', value }))}
                                            options={data.soleStiffening}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.right.soleStiffening}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                </FormGroup>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <FormControl
                            fullWidth
                        >
                            <FormLabel>Opmerking onderwerk/ schacht</FormLabel>
                            <Box className={classes.autoComplete}></Box>
                            <TextField
                                type='text'
                                value={osbOutsole.note}
                                multiline
                                rows={3}
                                onChange={event => dispatch(osbOutsoleData({ key: 'note', value: event.target.value }))} />
                        </FormControl>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

export default withStyles(useStyles)(OSBOutsole)
