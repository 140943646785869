@page {
    size: a4 portrait;
    margin: 10mm 15mm;

    @bottom-center {
        content: var(--document-title) ' — 'counter(page) '/' counter(pages);
    }
}

body {
    color: #333;
}

header {
    border-bottom: 2px solid black;
}

header h1, header h2, header h3, header h4, header h5, header h6,
thead h1, thead h2, thead h3, thead h4, header h5, header h6 {
    margin-bottom: 0;
}

header h1, header h2, header h3, header h4, header h5, header h6 {
    padding-bottom: 0.25em;
}

h1 .title-suffix {
    font-size: 0.75em;
    color: #666;
}

dl {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;    
    margin-right: 5mm;
    margin-bottom: 5mm;
}

dl > div {
    width: calc(20% - 5mm);
    flex-grow: 0;
    flex-shrink: 0;
}

dl dt {
    font-weight: bold;
}

dd {
    margin-left: 0;
}

table {
    border-collapse: collapse;
    width: 100%;
}

table td {
    padding: 0.25em;
}

table th {
    text-align: left;
    font-weight: normal;
    padding: 0.25em;
}

table thead th {
    border-bottom: 2px solid black;
    font-weight: bold;
    vertical-align: bottom;
}

table tbody td, table tbody th {
    border-bottom: 1px solid #ccc;
}

/**
 * Styling for specific properties
 */

.property-note {
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    /* Show as last */
    order: 99;
    /* Preserve whitespace added by humans */
    white-space: preserve;
}