import React from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary as MuiAccordionSummary,
    FormControl,
    FormGroup,
    FormLabel,
    Grid,
    Typography,
    Autocomplete,
    TextField,
    styled,
    Box
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { useSelector, useDispatch } from 'react-redux'
import { osaLowerAccordion, osaLowerData } from '../../store/reducers/osaLower'

import useStyles from './style'
import { withStyles } from '@mui/styles'

import dataProvider from '../../lib/dataProvider'
import { deepCopy } from '../../lib/util'

const data = dataProvider.osaLower

const osaLower = ({ classes }) => {
    // state management
    const dispatch = useDispatch()
    const osaLower = useSelector((state) => state.osaLower.osaLowerData)
    const error = useSelector((state) => state.osaLower.osaLowerErrors)
    const tab = useSelector((state) => state.osaLower.osaLowerAccordion)
    const [expanded, setExpanded] = React.useState(['panel1'])

    /**
     * Handle tab change
     * @param {*} event - event
     * @param {*} newValue - selected tab
     */
    function handleChange (accordionValue) {
        const newExpanded = deepCopy(expanded)

        if (newExpanded.includes(accordionValue)) {
            newExpanded.splice(newExpanded.indexOf(accordionValue), 1)
        } else {
            newExpanded.push(accordionValue)
        }

        setExpanded(newExpanded)
    }

    /**
     * Toggle the accordion.
     */
    function toggleAccordion () {
        dispatch(osaLowerAccordion({ key: 'accordion', value: !tab.accordion }))
    }

    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary
            expandIcon={<ExpandMore sx={{ fontSize: '0.9rem' }} />}
            {...props}
        />
    ))(({ theme }) => ({
        backgroundColor: 'rgba(0, 0, 0, .02)',
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(180deg)'
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1)
        }
    }))

    return (
        <Accordion defaultExpanded={true} expanded={tab.accordion}>
            <MuiAccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={toggleAccordion}
                className={!tab.accordion
                    ? tab.hasBeenOpened
                        ? tab.validate
                            ? classes.accordionSuccess
                            : classes.accordionError
                        : null
                    : null}
            >
                <Typography
                    className={!tab.accordion
                        ? tab.hasBeenOpened
                            ? tab.validate
                                ? classes.titleSuccess
                                : classes.titleError
                            : classes.title
                        : classes.title}>OSA Onderwerk & TVO</Typography>
            </MuiAccordionSummary>
            <AccordionDetails>
                <FormGroup>
                    <Accordion elevation={0} disableGutters expanded={expanded.includes('panel1')} onChange={(event) => handleChange('panel1')}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>Maakwijze en materiaal</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={4}>
                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                        error={error.developMethod}
                                    >
                                        <FormLabel>Maakwijze</FormLabel>
                                        <Autocomplete
                                            value={osaLower.developMethod}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'developMethod', value }))}
                                            options={data.developMethod}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.developMethod}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                        error={error.soleSpace}
                                    >
                                        <FormLabel>Ruimtezool</FormLabel>
                                        <Autocomplete
                                            value={osaLower.soleSpace}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'soleSpace', value }))}
                                            options={data.soleSpace}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.soleSpace}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                        error={error.insideSole}
                                    >
                                        <FormLabel>Binnenzool</FormLabel>
                                        <Autocomplete
                                            value={osaLower.insideSole}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'insideSole', value }))}
                                            options={data.insideSole}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.insideSole}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.cambering}
                                    >
                                        <FormLabel>Cambering links</FormLabel>
                                        <Autocomplete
                                            value={osaLower.cambering}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'cambering', side: 'left', value }))}
                                            options={data.cambering}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.left.cambering}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.cambering}
                                    >
                                        <FormLabel>Cambering rechts</FormLabel>
                                        <Autocomplete
                                            value={osaLower.right.cambering}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'cambering', side: 'right', value }))}
                                            options={data.cambering}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.right.cambering}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.contrefort}
                                    >
                                        <FormLabel>Contrefort links</FormLabel>
                                        <Autocomplete
                                            value={osaLower.contrefort}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'contrefort', side: 'left', value }))}
                                            options={data.contrefort}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.left.contrefort}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.contrefort}
                                    >
                                        <FormLabel>Contrefort rechts</FormLabel>
                                        <Autocomplete
                                            value={osaLower.right.contrefort}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'contrefort', side: 'right', value }))}
                                            options={data.contrefort}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.right.contrefort}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.contrefortMaterial}
                                    >
                                        <FormLabel>Contrefort materiaal</FormLabel>
                                        <Autocomplete
                                            value={osaLower.contrefortMaterial}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'contrefortMaterial', value }))}
                                            options={data.contrefortMaterial}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.contrefortMaterial}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}></Grid>

                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.anklePadding}
                                    >
                                        <FormLabel>Enkelpolstering links</FormLabel>
                                        <Autocomplete
                                            value={osaLower.left.anklePadding}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'anklePadding', side: 'left', value }))}
                                            options={data.anklePadding}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.left.anklePadding}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.anklePadding}
                                    >
                                        <FormLabel>Enkelpolstering rechts</FormLabel>
                                        <Autocomplete
                                            value={osaLower.right.anklePadding}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'anklePadding', side: 'right', value }))}
                                            options={data.anklePadding}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.right.anklePadding}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.anklePaddingMaterial}
                                    >
                                        <FormLabel>Enkelpolstering materiaal</FormLabel>
                                        <Autocomplete
                                            value={osaLower.anklePaddingMaterial}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'anklePaddingMaterial', value }))}
                                            options={data.anklePaddingMaterial}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.anklePaddingMaterial}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}></Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.schoringCTLateral}
                                    >
                                        <FormLabel>Schoring CT lat links (mm)</FormLabel>
                                        <Autocomplete
                                            value={osaLower.schoringCTLateral}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'schoringCTLateral', side: 'left', value }))}
                                            options={data.schoringCTLateral}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.left.schoringCTLateral}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.schoringCTLateral}
                                    >
                                        <FormLabel>Schoring CT lat rechts (mm)</FormLabel>
                                        <Autocomplete
                                            value={osaLower.right.schoringCTLateral}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'schoringCTLateral', side: 'right', value }))}
                                            options={data.schoringCTLateral}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.right.schoringCTLateral}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.schoringCTMedial}
                                    >
                                        <FormLabel>Schoring CT med links (mm)</FormLabel>
                                        <Autocomplete
                                            value={osaLower.schoringCTMedial}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'schoringCTMedial', side: 'left', value }))}
                                            options={data.schoringCTMedial}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.left.schoringCTMedial}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.schoringCTMedial}
                                    >
                                        <FormLabel>Schoring CT med rechts (mm)</FormLabel>
                                        <Autocomplete
                                            value={osaLower.right.schoringCTMedial}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'schoringCTMedial', side: 'right', value }))}
                                            options={data.schoringCTMedial}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.right.schoringCTMedial}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                        error={error.nose}
                                    >
                                        <FormLabel>Neuzen</FormLabel>
                                        <Autocomplete
                                            value={osaLower.nose}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'nose', value }))}
                                            options={data.nose}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.left.nose}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                        error={error.noseMaterial}
                                    >
                                        <FormLabel>Neus materiaal</FormLabel>
                                        <Autocomplete
                                            value={osaLower.noseMaterial}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'noseMaterial', value }))}
                                            options={data.noseMaterial}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.noseMaterial}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                        error={error.sideLining}
                                    >
                                        <FormLabel>Zijvoering</FormLabel>
                                        <Autocomplete
                                            value={osaLower.sideLining}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'sideLining', value }))}
                                            options={data.sideLining}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.sideLining}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion elevation={0} disableGutters expanded={expanded.includes('panel2')} onChange={(event) => handleChange('panel2')}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>Onderwerk</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={4}>
                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                        error={error.edges}
                                    >
                                        <FormLabel>Randen</FormLabel>
                                        <Autocomplete
                                            value={osaLower.edges}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'edges', value }))}
                                            options={data.edges}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.edges}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                        error={error.edgesMaterial}
                                    >
                                        <FormLabel>Randen materiaal</FormLabel>
                                        <Autocomplete
                                            value={osaLower.edgesMaterial}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'edgesMaterial', value }))}
                                            options={data.edgesMaterial}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.edgesMaterial}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                        error={error.midSole}
                                    >
                                        <FormLabel>Tussenzool</FormLabel>
                                        <Autocomplete
                                            value={osaLower.midSole}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'midSole', value }))}
                                            options={data.midSole}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.midSole}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                        error={error.outSole}
                                    >
                                        <FormLabel>Loopzool</FormLabel>
                                        <Autocomplete
                                            value={osaLower.outSole}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'outSole', value }))}
                                            options={data.outSole}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.outSole}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                        error={error.soleNumber}
                                    >
                                        <FormLabel>Zoolnummer</FormLabel>
                                        <Autocomplete
                                            value={osaLower.soleNumber}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'soleNumber', value }))}
                                            options={data.soleNumber}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.soleNumber}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                        error={error.toeParts}
                                    >
                                        <FormLabel>Teenstukken</FormLabel>
                                        <Autocomplete
                                            value={osaLower.toeParts}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'toeParts', value }))}
                                            options={data.toeParts}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.toeParts}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion elevation={0} disableGutters expanded={expanded.includes('panel3')} onChange={(event) => handleChange('panel3')}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>Hakken</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.heelHeight}
                                    >
                                        <FormLabel>Hakhoogte links (mm)</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder={data.heelHeight.min + ' - ' + data.heelHeight.max}
                                            value={osaLower.left.heelHeight}
                                            error={error.left.heelHeight}
                                            onChange={event => dispatch(osaLowerData({ key: 'heelHeight', side: 'left', value: event.target.value }))}/>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.heelHeight}
                                    >
                                        <FormLabel>Hakhoogte rechts (mm)</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder={data.heelHeight.min + ' - ' + data.heelHeight.max}
                                            value={osaLower.right.heelHeight}
                                            error={error.right.heelHeight}
                                            onChange={event => dispatch(osaLowerData({ key: 'heelHeight', side: 'right', value: event.target.value }))}/>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                        error={error.heelMaterial}
                                    >
                                        <FormLabel>Hakmateriaal</FormLabel>
                                        <Autocomplete
                                            value={osaLower.heelMaterial}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'heelMaterial', value }))}
                                            options={data.heelMaterial}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.heelMaterial}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                        error={error.heelModel}
                                    >
                                        <FormLabel>Hakmodel</FormLabel>
                                        <Autocomplete
                                            value={osaLower.heelModel}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'heelModel', value }))}
                                            options={data.heelModel}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.heelModel}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                        error={error.mudguard}
                                    >
                                        <FormLabel>Spatrand</FormLabel>
                                        <Autocomplete
                                            value={osaLower.mudguard}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'mudguard', value }))}
                                            options={data.mudguard}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.mudguard}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                        error={error.mudguardMaterial}
                                    >
                                        <FormLabel>Spatrand materiaal</FormLabel>
                                        <Autocomplete
                                            value={osaLower.mudguardMaterial}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'mudguardMaterial', value }))}
                                            options={data.mudguardMaterial}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.mudguardMaterial}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={8}></Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.boardSize}
                                    >
                                        <FormLabel>Boordmaat links</FormLabel>
                                        <Autocomplete
                                            value={osaLower.left.boardSize}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'boardSize', side: 'left', value }))}
                                            options={data.boardSize}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.left.boardSize}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.boardSize}
                                    >
                                        <FormLabel>Boordmaat rechts</FormLabel>
                                        <Autocomplete
                                            value={osaLower.right.boardSize}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'boardSize', side: 'right', value }))}
                                            options={data.boardSize}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.right.boardSize}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.woodenHeelType}
                                    >
                                        <FormLabel>Houten haktype links</FormLabel>
                                        <Autocomplete
                                            value={osaLower.left.woodenHeelType}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'woodenHeelType', side: 'left', value }))}
                                            options={data.woodenHeelType}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.left.woodenHeelType}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.woodenHeelType}
                                    >
                                        <FormLabel>Houten haktype rechts</FormLabel>
                                        <Autocomplete
                                            value={osaLower.right.woodenHeelType}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'woodenHeelType', side: 'right', value }))}
                                            options={data.woodenHeelType}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.right.woodenHeelType}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.heelStructure}
                                    >
                                        <FormLabel>Hakopbouw</FormLabel>
                                        <Autocomplete
                                            value={osaLower.heelStructure}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'heelStructure', value }))}
                                            options={data.heelStructure}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.heelStructure}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}></Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.schoringLat}
                                    >
                                        <FormLabel>Schoring lateraal links (mm)</FormLabel>
                                        <Autocomplete
                                            value={osaLower.left.schoringLat}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'schoringLat', side: 'left', value }))}
                                            options={data.schoringLat}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.left.schoringLat}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.schoringLat}
                                    >
                                        <FormLabel>Schoring lateraal rechts (mm)</FormLabel>
                                        <Autocomplete
                                            value={osaLower.right.schoringLat}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'schoringLat', side: 'right', value }))}
                                            options={data.schoringLat}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.right.schoringLat}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.schoringMed}
                                    >
                                        <FormLabel>Schoring mediaal links</FormLabel>
                                        <Autocomplete
                                            value={osaLower.left.schoringMed}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'schoringMed', side: 'left', value }))}
                                            options={data.schoringMed}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.left.schoringMed}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.schoringMed}
                                    >
                                        <FormLabel>Schoring mediaal rechts</FormLabel>
                                        <Autocomplete
                                            value={osaLower.right.schoringMed}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'schoringMed', side: 'right', value }))}
                                            options={data.schoringMed}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.right.schoringMed}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.rounding}
                                    >
                                        <FormLabel>Afronden links</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={osaLower.left.rounding}
                                            error={error.left.rounding}
                                            onChange={event => dispatch(osaLowerData({ key: 'rounding', side: 'left', value: event.target.value }))}/>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.rounding}
                                    >
                                        <FormLabel>Afronden rechts</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={osaLower.right.rounding}
                                            error={error.right.rounding}
                                            onChange={event => dispatch(osaLowerData({ key: 'rounding', side: 'right', value: event.target.value }))}/>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                        error={error.heelBuffer}
                                    >
                                        <FormLabel>Hakbuffer</FormLabel>
                                        <Autocomplete
                                            value={osaLower.heelBuffer}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'heelBuffer', value }))}
                                            options={data.heelBuffer}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.heelBuffer}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                        error={error.backflap}
                                    >
                                        <FormLabel>Achterlap</FormLabel>
                                        <Autocomplete
                                            value={osaLower.backflap}
                                            onChange={(event, value) => dispatch(osaLowerData({ key: 'backflap', value }))}
                                            options={data.backflap}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.backflap}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </FormGroup>
                <Grid container spacing={4}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}>
                        <FormControl
                            fullWidth
                        >
                            <FormLabel>Opmerking onderwerk</FormLabel>
                            <Box className={classes.autoComplete}></Box>
                            <TextField
                                type='text'
                                value={osaLower.note}
                                multiline
                                rows={3}
                                onChange={event => dispatch(osaLowerData({ key: 'note', value: event.target.value }))} />
                        </FormControl>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

export default withStyles(useStyles)(osaLower)
