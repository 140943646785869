import React from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary as MuiAccordionSummary,
    FormControl,
    // FormGroup,
    FormLabel,
    Grid,
    Typography,
    Autocomplete,
    TextField,
    styled,
    FormControlLabel,
    Checkbox,
    Box, InputAdornment
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { useSelector, useDispatch } from 'react-redux'
import { repairAccordion, repairData } from '../../store/reducers/repair'

import useStyles from './style'
import { withStyles } from '@mui/styles'
import { deepCopy } from '../../lib/util'

import dataProvider from '../../lib/dataProvider'
import translation from '../../lib/translations.json'

const data = dataProvider.repair

/**
 * @borrows MyComboBox as _MyComboBox
 * @private
 */
const _MyComboBox = ({classes, xs = 6, label, stateKey, side}) => {
    const dispatch = useDispatch()
    const repair = useSelector((state) => state.repair.repairData)
    const error = useSelector((state) => state.repair.repairErrors)

    let sideKey = side ?? 'left'
    let sideName = side === undefined
        ? ''
        : translation[side];
    return (
        <Grid item xs={xs}>
            <FormControl
                fullWidth
                error={error[sideKey][stateKey]}
            >
                <FormLabel>{label} {sideName}</FormLabel>
                <Autocomplete
                    value={repair[sideKey][stateKey]}
                    onChange={(event, value) => dispatch(repairData({ key: stateKey, side: sideKey, value }))}
                    options={data[stateKey]}
                    className={classes.autoComplete}
                    renderInput={(params) =>
                        <TextField
                            error={error[sideKey][stateKey]}
                            {...params} />
                    } />
            </FormControl>
        </Grid>
    )
}

/**
 * A combobox based on values
 *
 * Automatically retrieves data values from `dataProvider[stateKey]` and saves the value into `state.repair[stateKey]`
 *
 * @param {Object} props
 * @param {Number} props.xs Amount of columns to take
 * @param {String} props.label Label above the input element
 * @param {String} props.stateKey The key in the state to load and apply the value to
 * @param {'left' | 'right' | undefined } props.side The side this applies to
 * @returns {Element}
 * @borrows _MyComboBox as MyComboBox
 */
const MyComboBox = withStyles(useStyles)(_MyComboBox)

/**
 * A TextField with a label, positioned in a grid, of which the value is synced to `state.repair.repairData[side][stateKey]`
 *
 * @param {Object} props
 * @param {String} props.label The label to show above the text box
 * @param {'left' | 'right'} props.side The side this field is for
 * @param {String} props.stateKey Key in the state where the value is bound to
 * @param {String} props.placeholder Text to show while no text is entered
 * @param {Number} props.xs Grid column span
 */
const _MyTextBox = ({
    classes,
    label,
    side,
    stateKey,
    xs = 6,
    placeholder= undefined,
    unit = null,
    type = 'text'
}) => {
    const dispatch = useDispatch()
    const repair = useSelector((state) => state.repair.repairData)
    const error = useSelector((state) => state.repair.repairErrors)

    let sideName = translation[side];
    unit ??= ''
    return (<Grid item xs={xs}>
        <FormControl
            fullWidth
            error={error[side][stateKey]}
        >
            <FormLabel>{`${label} ${sideName} ${unit}`}</FormLabel>
            <Box class={classes.autoComplete}></Box>
            <TextField
                type={type}
                placeholder={placeholder}
                value={repair[side][stateKey]}
                error={error[side][stateKey]}
                onChange={event => dispatch(repairData({ key: stateKey, side: side, value: event.target.value }))}
            />
        </FormControl>
    </Grid>)
}

const MyTextBox = withStyles(useStyles)(_MyTextBox)

/**
 * Creates a row that contains two checkboxes, one for left and one for right,
 * with the specified label that updates the value in the store with the specified statekey
 *
 * @param {Object} props
 * @param {String} props.stateKey The key to bind this checkbox checked property to
 * @param {String} props.label The label visible in the UI for this checkbox
 * @returns {JSX.Element}
 */
const LeftRightCheckboxRow = ({label, stateKey}) => {
    const dispatch = useDispatch()
    const repair = useSelector((state) => state.repair.repairData)

    return (<>
        <Grid item xs={2}></Grid>
        {['left', 'right'].map(side =>
            <Grid item xs={4} key={`${label}-${side}`}>
                <FormControl fullWidth>
                    <FormControlLabel
                        label={`${label} ${translation[side]}`}
                        control={
                            <Checkbox
                                checked={repair[side][stateKey]}
                                onChange={event => dispatch(repairData({ value: event.target.checked, side, key: stateKey }))} />}
                    />
                </FormControl>
            </Grid>
        )}
        <Grid item xs={2}></Grid>
    </>)
}

const repair = ({ classes }) => {
    // state management
    const dispatch = useDispatch()
    const repair = useSelector((state) => state.repair.repairData)
    const error = useSelector((state) => state.repair.repairErrors)
    const tab = useSelector((state) => state.repair.repairAccordion)
    const [expanded, setExpanded] = React.useState(['panel1'])
    // const other = useSelector((state) => state.other)

    /**
     * Toggle the accordion.
     */
    function toggleAccordion () {
        dispatch(repairAccordion({ key: 'accordion', value: !tab.accordion }))
    }

    /**
     * Handle tab change
     * @param {*} event - event
     * @param {*} newValue - selected tab
     */
    function handleChange (accordionValue) {
        const newExpanded = deepCopy(expanded)

        if (newExpanded.includes(accordionValue)) {
            newExpanded.splice(newExpanded.indexOf(accordionValue), 1)
        } else {
            newExpanded.push(accordionValue)
        }

        setExpanded(newExpanded)
    }

    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary
            expandIcon={<ExpandMore sx={{ fontSize: '0.9rem' }} />}
            {...props}
        />
    ))(({ theme }) => ({
        backgroundColor: 'rgba(0, 0, 0, .02)',
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(180deg)'
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1)
        }
    }))

    /**
     * Set send to podoIt true or false.
     *
     * @param {Boolean} event - bool
     */
    // function setCheckedTonguePad (event, side) {
    //     // dispatch(sampleShoeData({ key: 'tonguePad', side, value: event.target.checked }))
    // };



    const SIDES = ['left', 'right']

    return (
        <Accordion defaultExpanded={true} expanded={tab.accordion}>
            <MuiAccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={toggleAccordion}
                className={!tab.accordion
                    ? tab.hasBeenOpened
                        ? tab.validate
                            ? classes.accordionSuccess
                            : classes.accordionError
                        : null
                    : null}
            >
                <Typography
                    className={!tab.accordion
                        ? tab.hasBeenOpened
                            ? tab.validate
                                ? classes.titleSuccess
                                : classes.titleError
                            : classes.title
                        : classes.title}>Reparatie/ aanpassing</Typography>
            </MuiAccordionSummary>
            <AccordionDetails>
                <Accordion elevation={0} disableGutters expanded={expanded.includes('panel1')} onChange={(event) => handleChange('panel1')}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography>Schacht</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={4}>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.left.stretch}
                                >
                                    <FormLabel>Oprekken links</FormLabel>
                                    <Autocomplete
                                        value={repair.left.stretch}
                                        onChange={(event, value) => dispatch(repairData({ key: 'stretch', side: 'left', value }))}
                                        options={data.stretch}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.left.stretch}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.right.stretch}
                                >
                                    <FormLabel>Oprekken rechts</FormLabel>
                                    <Autocomplete
                                        value={repair.right.stretch}
                                        onChange={(event, value) => dispatch(repairData({ key: 'stretch', side: 'right', value }))}
                                        options={data.stretch}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.right.stretch}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.left.solePad}
                                >
                                    <FormLabel>Tongpolster links</FormLabel>
                                    <Autocomplete
                                        value={repair.left.solePad}
                                        onChange={(event, value) => dispatch(repairData({ key: 'solePad', side: 'left', value }))}
                                        options={data.solePad}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.left.solePad}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.right.solePad}
                                >
                                    <FormLabel>Tongpolster rechts</FormLabel>
                                    <Autocomplete
                                        value={repair.right.solePad}
                                        onChange={(event, value) => dispatch(repairData({ key: 'solePad', side: 'right', value }))}
                                        options={data.solePad}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.right.solePad}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.left.closureAdjust}
                                >
                                    <FormLabel>Sluiting aanpassen links</FormLabel>
                                    <Autocomplete
                                        value={repair.left.closureAdjust}
                                        onChange={(event, value) => dispatch(repairData({ key: 'closureAdjust', side: 'left', value }))}
                                        options={data.closureAdjust}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.left.closureAdjust}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.right.closureAdjust}
                                >
                                    <FormLabel>Sluiting aanpassen rechts</FormLabel>
                                    <Autocomplete
                                        value={repair.right.closureAdjust}
                                        onChange={(event, value) => dispatch(repairData({ key: 'closureAdjust', side: 'right', value }))}
                                        options={data.closureAdjust}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.right.closureAdjust}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container spacing={1}>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Klittenband aan tong links"
                                        control={
                                            <Checkbox
                                                checked={repair.left.tongueVelcro}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'left', key: 'tongueVelcro' }))} />}

                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Klittenband aan tong rechts"
                                        control={
                                            <Checkbox
                                                checked={repair.right.tongueVelcro}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'right', key: 'tongueVelcro' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Kleefband vervangen links"
                                        control={
                                            <Checkbox
                                                checked={repair.left.replaceAdhesive}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'left', key: 'replaceAdhesive' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Kleefband vervangen rechts"
                                        control={
                                            <Checkbox
                                                checked={repair.right.replaceAdhesive}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'right', key: 'replaceAdhesive' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Tong vaststikken links"
                                        control={
                                            <Checkbox
                                                checked={repair.left.tongueStitching}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'left', key: 'tongueStitching' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Tong vaststikken rechts"
                                        control={
                                            <Checkbox
                                                checked={repair.right.tongueStitching}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'right', key: 'tongueStitching' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Hielvoering links"
                                        control={
                                            <Checkbox
                                                checked={repair.left.heelLining}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'left', key: 'heelLining' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Hielvoering rechts"
                                        control={
                                            <Checkbox
                                                checked={repair.right.heelLining}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'right', key: 'heelLining' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Stiksel herstellen links"
                                        control={
                                            <Checkbox
                                                checked={repair.left.repairStitching}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'left', key: 'repairStitching' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Stiksel herstellen rechts"
                                        control={
                                            <Checkbox
                                                checked={repair.right.repairStitching}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'right', key: 'repairStitching' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Klittenband verlengen links"
                                        control={
                                            <Checkbox
                                                checked={repair.left.extendVelcro}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'left', key: 'extendVelcro' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Klittenband verlengen rechts"
                                        control={
                                            <Checkbox
                                                checked={repair.right.extendVelcro}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'right', key: 'extendVelcro' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Klittenband verkorten links"
                                        control={
                                            <Checkbox
                                                checked={repair.left.shortenVelcro}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'left', key: 'shortenVelcro' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Klittenband verkorten rechts"
                                        control={
                                            <Checkbox
                                                checked={repair.right.shortenVelcro}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'right', key: 'shortenVelcro' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}></Grid>

                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Herstel haken/ringen links"
                                        control={
                                            <Checkbox
                                                checked={repair.left.mendHooksOrRings}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'left', key: 'mendHooksOrRings' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Herstel haken/ringen rechts"
                                        control={
                                            <Checkbox
                                                checked={repair.right.mendHooksOrRings}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'right', key: 'mendHooksOrRings' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}></Grid>

                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Herstel rits links"
                                        control={
                                            <Checkbox
                                                checked={repair.left.mendZipper}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'left', key: 'mendZipper' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Herstel rits rechts"
                                        control={
                                            <Checkbox
                                                checked={repair.right.mendZipper}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'right', key: 'mendZipper' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}></Grid>
                        </Grid>
                        <Grid container spacing={4}>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.left.openPosition}
                                >
                                    <FormLabel>Openstand links (mm)</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        placeholder='0'
                                        value={repair.left.openPosition}
                                        error={error.left.openPosition}
                                        onChange={event => dispatch(repairData({ key: 'openPosition', side: 'left', value: event.target.value }))}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.right.openPosition}
                                >
                                    <FormLabel>Openstand rechts (mm)</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        placeholder='0'
                                        value={repair.right.openPosition}
                                        error={error.right.openPosition}
                                        onChange={event => dispatch(repairData({ key: 'openPosition', side: 'right', value: event.target.value }))}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion elevation={0} disableGutters expanded={expanded.includes('panel2')} onChange={(event) => handleChange('panel2')}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography>Onderwerk</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Zool rubber links"
                                        control={
                                            <Checkbox
                                                checked={repair.left.rubberSole}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'left', key: 'rubberSole' }))} />}

                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Zool rubber rechts"
                                        control={
                                            <Checkbox
                                                checked={repair.right.rubberSole}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'right', key: 'rubberSole' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Zool leer links"
                                        control={
                                            <Checkbox
                                                checked={repair.left.leatherSole}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'left', key: 'leatherSole' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Zool leer rechts"
                                        control={
                                            <Checkbox
                                                checked={repair.right.leatherSole}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'right', key: 'leatherSole' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Hiel links"
                                        control={
                                            <Checkbox
                                                checked={repair.left.heels}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'left', key: 'heels' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Hiel rechts"
                                        control={
                                            <Checkbox
                                                checked={repair.right.heels}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'right', key: 'heels' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Zool algemeen links"
                                        control={
                                            <Checkbox
                                                checked={repair.left.generalSole}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'left', key: 'generalSole' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Zool algemeen rechts"
                                        control={
                                            <Checkbox
                                                checked={repair.right.generalSole}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'right', key: 'generalSole' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Bufferhak links"
                                        control={
                                            <Checkbox
                                                checked={repair.left.heelBuffer}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'left', key: 'heelBuffer' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Bufferhak rechts"
                                        control={
                                            <Checkbox
                                                checked={repair.right.heelBuffer}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'right', key: 'heelBuffer' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Hakafronding links"
                                        control={
                                            <Checkbox
                                                checked={repair.left.heelRounding}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'left', key: 'heelRounding' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Hakafronding rechts"
                                        control={
                                            <Checkbox
                                                checked={repair.right.heelRounding}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'right', key: 'heelRounding' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}></Grid>

                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Teenstukken links"
                                        control={
                                            <Checkbox
                                                checked={repair.left.toePieces}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'left', key: 'toePieces' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Teenstukken rechts"
                                        control={
                                            <Checkbox
                                                checked={repair.right.toePieces}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'right', key: 'toePieces' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}></Grid>

                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Lijmwerk links"
                                        control={
                                            <Checkbox
                                                checked={repair.left.adhesive}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'left', key: 'adhesive' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Lijmwerk rechts"
                                        control={
                                            <Checkbox
                                                checked={repair.right.adhesive}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'right', key: 'adhesive' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}></Grid>

                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Nieuw dekje links"
                                        control={
                                            <Checkbox
                                                checked={repair.left.newCover}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'left', key: 'newCover' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Nieuw dekje rechts"
                                        control={
                                            <Checkbox
                                                checked={repair.right.newCover}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'right', key: 'newCover' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}></Grid>
                        </Grid>
                        <Grid container spacing={4}>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.left.rocker}
                                >
                                    <FormLabel>Afwikkelbalk links</FormLabel>
                                    <Autocomplete
                                        value={repair.left.rocker}
                                        onChange={(event, value) => dispatch(repairData({ key: 'rocker', side: 'left', value }))}
                                        options={data.rocker}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.right.rocker}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.right.rocker}
                                >
                                    <FormLabel>Afwikkelbalk rechts</FormLabel>
                                    <Autocomplete
                                        value={repair.right.rocker}
                                        onChange={(event, value) => dispatch(repairData({ key: 'rocker', side: 'right', value }))}
                                        options={data.rocker}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.right.rocker}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.right.heel}
                                >
                                    <FormLabel>Hak links (mm)</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        value={repair.left.heel}
                                        error={error.left.heel}
                                        onChange={event => dispatch(repairData({ key: 'heel', side: 'left', value: event.target.value }))}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.right.heel}
                                >
                                    <FormLabel>Hak rechts (mm)</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        value={repair.right.heel}
                                        error={error.right.heel}
                                        onChange={event => dispatch(repairData({ key: 'heel', side: 'right', value: event.target.value }))}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.right.heel}
                                >
                                    <FormLabel>Hak links (mm)</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        value={repair.left.heel}
                                        error={error.left.heel}
                                        onChange={event => dispatch(repairData({ key: 'heel', side: 'left', value: event.target.value }))}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.right.heel}
                                >
                                    <FormLabel>Hak rechts (mm)</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        value={repair.right.heel}
                                        error={error.right.heel}
                                        onChange={event => dispatch(repairData({ key: 'heel', side: 'right', value: event.target.value }))}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.right.heelElevation}
                                >
                                    <FormLabel>Hakverhoging links (mm)</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        value={repair.left.heelElevation}
                                        error={error.left.heelElevation}
                                        onChange={event => dispatch(repairData({ key: 'heelElevation', side: 'left', value: event.target.value }))}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.right.heelElevation}
                                >
                                    <FormLabel>Hakverhoging rechts (mm)</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        value={repair.right.heelElevation}
                                        error={error.right.heelElevation}
                                        onChange={event => dispatch(repairData({ key: 'heelElevation', side: 'right', value: event.target.value }))}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.left.ball}
                                >
                                    <FormLabel>Bal links (mm)</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        value={repair.left.ball}
                                        error={error.left.ball}
                                        onChange={event => dispatch(repairData({ key: 'ball', side: 'left', value: event.target.value }))}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.right.ball}
                                >
                                    <FormLabel>Bal rechts (mm)</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        value={repair.right.ball}
                                        error={error.right.ball}
                                        onChange={event => dispatch(repairData({ key: 'ball', side: 'right', value: event.target.value }))}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.left.toe}
                                >
                                    <FormLabel>Teen links (mm)</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        value={repair.left.toe}
                                        error={error.left.toe}
                                        onChange={event => dispatch(repairData({ key: 'toe', side: 'left', value: event.target.value }))}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.right.toe}
                                >
                                    <FormLabel>Teen rechts (mm)</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        value={repair.right.toe}
                                        error={error.right.toe}
                                        onChange={event => dispatch(repairData({ key: 'toe', side: 'right', value: event.target.value }))}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={error.left.correction}
                                >
                                    <FormLabel>Correctie links</FormLabel>
                                    <Autocomplete
                                        value={repair.left.correction}
                                        onChange={(event, value) => dispatch(repairData({ key: 'correction', side: 'left', value }))}
                                        options={data.correction}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.left.correction}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={error.left.correctionHeight}
                                >
                                    <FormLabel>Hoogte correctie links</FormLabel>
                                    <Autocomplete
                                        value={repair.left.correctionHeight}
                                        onChange={(event, value) => dispatch(repairData({ key: 'correctionHeight', side: 'left', value }))}
                                        options={data.correctionHeight}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.left.correctionHeight}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>

                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={error.right.correction}
                                >
                                    <FormLabel>Correctie rechts</FormLabel>
                                    <Autocomplete
                                        value={repair.right.correction}
                                        onChange={(event, value) => dispatch(repairData({ key: 'correction', side: 'right', value }))}
                                        options={data.correction}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.right.correction}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={error.right.correctionHeight}
                                >
                                    <FormLabel>Hoogte correctie rechts</FormLabel>
                                    <Autocomplete
                                        value={repair.right.correctionHeight}
                                        onChange={(event, value) => dispatch(repairData({ key: 'correctionHeight', side: 'right', value }))}
                                        options={data.correctionHeight}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.right.correctionHeight}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion elevation={0} disableGutters expanded={expanded.includes('panel3')} onChange={(event) => handleChange('panel3')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" expandIcon={<ExpandMore />}>
                        <Typography>Hakschoring</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={4}>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.left.lateral}
                                >
                                    <FormLabel>Hakschoring lateraal links</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        value={repair.left.lateral}
                                        error={error.left.lateral}
                                        onChange={event => dispatch(repairData({ key: 'lateral', side: 'left', value: event.target.value }))}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.right.lateral}
                                >
                                    <FormLabel>Hakschoring lateraal rechts</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        value={repair.right.lateral}
                                        error={error.right.lateral}
                                        onChange={event => dispatch(repairData({ key: 'lateral', side: 'right', value: event.target.value }))}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.left.medial}
                                >
                                    <FormLabel>Hakschoring mediaal links</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        value={repair.left.medial}
                                        error={error.left.medial}
                                        onChange={event => dispatch(repairData({ key: 'medial', side: 'left', value: event.target.value }))}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.right.medial}
                                >
                                    <FormLabel>Hakschoring mediaal rechts</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        value={repair.right.medial}
                                        error={error.right.medial}
                                        onChange={event => dispatch(repairData({ key: 'medial', side: 'right', value: event.target.value }))}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion elevation={0} disableGutters expanded={expanded.includes('panel4')} onChange={(event) => handleChange('panel4')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" expandIcon={<ExpandMore />}>
                        <Typography>Zoolverstijving</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={4}>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.left.soleStiffening}
                                >
                                    <FormLabel>Zoolverstijving links</FormLabel>
                                    <Autocomplete
                                        value={repair.left.soleStiffening}
                                        onChange={(event, value) => dispatch(repairData({ key: 'soleStiffening', side: 'left', value }))}
                                        options={data.soleStiffening}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.left.soleStiffening}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.right.soleStiffening}
                                >
                                    <FormLabel>Zoolverstijving rechts</FormLabel>
                                    <Autocomplete
                                        value={repair.right.soleStiffening}
                                        onChange={(event, value) => dispatch(repairData({ key: 'soleStiffening', side: 'right', value }))}
                                        options={data.soleStiffening}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.right.soleStiffening}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion elevation={0} disableGutters expanded={expanded.includes('panel5')} onChange={() => handleChange('panel5')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" expandIcon={<ExpandMore />}>
                        <Typography>Steunzolen en folie</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={4}>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.left.insoles}
                                >
                                    <FormLabel>Zool</FormLabel>
                                    <Autocomplete
                                        value={repair.left.insoles}
                                        onChange={(event, value) => dispatch(repairData({ key: 'insoles', side: 'left', value }))}
                                        options={data.insoles}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.left.insoles}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>

                            <Grid item xs={6} />

                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={error.right.shoeSize}
                                >
                                    <FormLabel>Schoenmaat links</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        value={repair.left.shoeSize}
                                        error={error.left.shoeSize}
                                        onChange={event => dispatch(repairData({ key: 'shoeSize', side: 'left', value: event.target.value }))}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={error.left.shoeWidth}
                                >
                                    <FormLabel>Wijdtemaat links</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        value={repair.left.shoeWidth}
                                        error={error.left.shoeWidth}
                                        onChange={event => dispatch(repairData({ key: 'shoeWidth', side: 'left', value: event.target.value }))}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={error.right.shoeSize}
                                >
                                    <FormLabel>Schoenmaat rechts</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        value={repair.right.shoeSize}
                                        error={error.right.shoeSize}
                                        onChange={event => dispatch(repairData({ key: 'shoeSize', side: 'right', value: event.target.value }))}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={error.right.shoeWidth}
                                >
                                    <FormLabel>Wijdtemaat rechts</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        value={repair.right.shoeWidth}
                                        error={error.right.shoeWidth}
                                        onChange={event => dispatch(repairData({ key: 'shoeWidth', side: 'right', value: event.target.value }))}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.left.ucbl}
                                >
                                    <FormLabel>UCBL links</FormLabel>
                                    <Autocomplete
                                        value={repair.left.ucbl}
                                        onChange={(event, value) => dispatch(repairData({ key: 'ucbl', side: 'left', value }))}
                                        options={data.ucbl}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.left.ucbl}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.right.ucbl}
                                >
                                    <FormLabel>UCBL rechts</FormLabel>
                                    <Autocomplete
                                        value={repair.right.ucbl}
                                        onChange={(event, value) => dispatch(repairData({ key: 'ucbl', side: 'right', value }))}
                                        options={data.ucbl}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.right.ucbl}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.left.forefootThickness}
                                >
                                    <FormLabel>Dikte voorvoet links</FormLabel>
                                    <Autocomplete
                                        value={repair.left.forefootThickness}
                                        onChange={(event, value) => dispatch(repairData({ key: 'forefootThickness', side: 'left', value }))}
                                        options={data.forefootThickness}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.left.forefootThickness}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.right.forefootThickness}
                                >
                                    <FormLabel>Dikte voorvoet rechts</FormLabel>
                                    <Autocomplete
                                        value={repair.right.forefootThickness}
                                        onChange={(event, value) => dispatch(repairData({ key: 'forefootThickness', side: 'right', value }))}
                                        options={data.forefootThickness}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.right.forefootThickness}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.left.insoleMaterial}
                                >
                                    <FormLabel>Materiaal links</FormLabel>
                                    <Autocomplete
                                        value={repair.left.insoleMaterial}
                                        onChange={(event, value) => dispatch(repairData({ key: 'insoleMaterial', side: 'left', value }))}
                                        options={data.insoleMaterial}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.left.insoleMaterial}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.right.insoleMaterial}
                                >
                                    <FormLabel>Materiaal rechts</FormLabel>
                                    <Autocomplete
                                        value={repair.right.insoleMaterial}
                                        onChange={(event, value) => dispatch(repairData({ key: 'insoleMaterial', side: 'right', value }))}
                                        options={data.insoleMaterial}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.right.insoleMaterial}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.left.insoleLength}
                                >
                                    <FormLabel>Lengte links</FormLabel>
                                    <Autocomplete
                                        value={repair.left.insoleLength}
                                        onChange={(event, value) => dispatch(repairData({ key: 'insoleLength', side: 'left', value }))}
                                        options={data.insoleLength}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.left.insoleLength}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.right.insoleLength}
                                >
                                    <FormLabel>Lengte rechts</FormLabel>
                                    <Autocomplete
                                        value={repair.right.insoleLength}
                                        onChange={(event, value) => dispatch(repairData({ key: 'insoleLength', side: 'right', value }))}
                                        options={data.insoleLength}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.right.insoleLength}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>

                            <Grid item xs={2} />
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Folie over leest links"
                                        control={
                                            <Checkbox
                                                checked={repair.left.foilOverLast}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'left', key: 'foilOverLast' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Folie over leest rechts"
                                        control={
                                            <Checkbox
                                                checked={repair.right.foilOverLast}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'right', key: 'foilOverLast' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2} />

                            <Grid item xs={2} />
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Folie over bestaand supplement links"
                                        control={
                                            <Checkbox
                                                checked={repair.left.foilOverExistingSupplement}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'left', key: 'foilOverExistingSupplement' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Folie over bestaand supplement rechts"
                                        control={
                                            <Checkbox
                                                checked={repair.right.foilOverExistingSupplement}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'right', key: 'foilOverExistingSupplement' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2} />
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion elevation={0} disableGutters expanded={expanded.includes('panel6')} onChange={() => handleChange('panel6')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" expandIcon={<ExpandMore />}>
                        <Typography>Kosten</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={4} alignItems='center'>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Garantie"
                                        control={
                                            <Checkbox
                                                checked={repair.warranty}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, key: 'warranty' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Service"
                                        control={
                                            <Checkbox
                                                checked={repair.service}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, key: 'service' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <FormControlLabel
                                        label="Eigen rekening klant"
                                        control={
                                            <Checkbox
                                                checked={repair.customerCosts !== ''}
                                                onChange={event => dispatch(repairData({ value: event.target.checked ? 0 : '', key: 'customerCosts' }))} />}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={2}>
                                <FormControl
                                    fullWidth
                                    error={error.customerCosts}
                                >
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        value={repair.customerCosts}
                                        error={error.customerCosts}
                                        disabled={repair.customerCosts === ''}
                                        min={0}
                                        step='.01'
                                        onChange={event => dispatch(repairData({ key: 'customerCosts', value: parseFloat(event.target.value) }))}
                                        inputProps={{
                                            step: '.01',
                                        }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                        }}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={2}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Prijs instelling"
                                        control={
                                            <Checkbox
                                                checked={repair.institutionCosts !== ''}
                                                onChange={event => dispatch(repairData({ value: event.target.checked ? 0 : '', key: 'institutionCosts' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl
                                    fullWidth
                                    error={error.institutionCosts}
                                >
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        value={repair.institutionCosts}
                                        error={error.institutionCosts}
                                        disabled={repair.institutionCosts === ''}
                                        onChange={event => dispatch(repairData({ key: 'institutionCosts', value: parseFloat(event.target.value) }))}
                                        inputProps={{
                                            step: '0.01'
                                        }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                        }}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={2}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Prijs verzekering"
                                        control={
                                            <Checkbox
                                                checked={repair.insuranceCosts !== ''}
                                                onChange={event => dispatch(repairData({ value: event.target.checked ? 0 : '', key: 'insuranceCosts' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl
                                    fullWidth
                                    error={error.insuranceCosts }
                                >
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        value={repair.insuranceCosts }
                                        error={error.insuranceCosts}
                                        disabled={repair.insuranceCosts === ''}
                                        onChange={event => dispatch(repairData({ key: 'insuranceCosts', value: parseFloat(event.target.value) }))}
                                        inputProps={{
                                            step: '0.01'
                                        }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Grid container spacing={4}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}>
                        <FormControl
                            fullWidth
                        >
                            <FormLabel>Opmerking reparatie</FormLabel>
                            <Box className={classes.autoComplete}></Box>
                            <TextField
                                type='text'
                                value={repair.note}
                                multiline
                                rows={3}
                                onChange={event => dispatch(repairData({ key: 'note', value: event.target.value }))} />
                        </FormControl>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

export default withStyles(useStyles)(repair)
