import axios from 'axios'
import { REACT_APP_API_URL, IDENTITY_REALM, IDENTITY_URL, FOLDER_LOCATION, BACKEND } from '../env'
import UserService from './userService'
import moment from 'moment'

const translation = require('./translations.json')

/**
 * Class API
 * @version 1.0.0
 */
class API {
    /**
     * Get api url.
     *
     * @version 1.0.0
     */
    get apiUrl () {
        return REACT_APP_API_URL
    }

    /**
     * Constructor.
     * @version 1.0.0
     */
    constructor () {
        // this.handleErrors = this.handleErrors.bind(this);
        this.backendUrl = BACKEND
        this.sharepointToken = ''
        this.accountLinkUrl = '/'

        this.wittepoelSite = 'voetencentrumwender590.sharepoint.com,ab3332f1-3ffe-44c2-b8b0-8d4c8d694cd7,260264f5-ccb4-4955-b8e2-5825a9cd9cb5'
        this.wittepoelDriveID = 'b!8TIzq_4_wkS4sI1MjWlM1_VkAia0zFVJuOJYJanNnLW9LdzewnjNQql9DzW-XD7R'

        this.wenderSite = 'voetencentrumwender590.sharepoint.com,5db3cbfd-afb9-4e2c-bebf-bf20b096ff68,27b33d2a-8432-41a2-926d-18a075b384c9'
        this.wenderDriveID = 'b!_cuzXbmvLE6-v78gsJb_aCo9sycyhKJBkm0YoHWzhMkH9h1t788xSYzPfgPpKPWP'
    }

    /**
     * Handle the retrieved error from the API
     *
     * @param {Object} errors - object with error information
     */
    handleASPErrors (errors) {
        try {
            if (errors) {
                const message = ['The following errors occurred:']
                for (const key in errors) {
                    const errs = errors[key]
                    message.push(`  ${key}`)
                    for (const value of errs) {
                        message.push(`   - ${value}`)
                    }
                }
                console.error('ASP.NET\n', message.join('\n'))
            }
        } catch (e) {
            console.error('Response.errors is not as was expected', e, errors)
        }
    }

    /**
     * Handles shared internal logic for requests.
     * The response can be handled using both awaiting the result and using promises
     *
     * @param  {string   } method    The method, GET, POST, you name it.
     * @param  {string   } path      The path of the request. Only requests to
     * @param  {Object   } body      The body of the request. This will be turned into JSON automatically.
     * @param  {Object   } query     The query of the request.
     * @param  {Function?} onSuccess The callback called on a successful fetch. it is optional.
     * @param  {Function?} onError   The callback called when an error occurs.  it is optional.
     * @return {Promise<Object>} a promise with the json content
     */
    async JSONRequest (method, path, body, onSuccess, onError) {
        if (this.backendUrl === '') {
            onError('Geen backend geselecteerd')
            return
        }

        try {
            const response = await axios({
                method,
                url: `${this.apiUrl}${path}`,
                data: body,
                headers: {
                    Token: UserService.getToken(),
                    Backend: this.backendUrl,
                    Realm: IDENTITY_REALM,
                    KeycloakEndpoint: IDENTITY_URL,
                    isBootform: 'true'
                }
            })
            if (onSuccess) onSuccess(response.data)
            return response
        } catch (error) {
            if (error?.response?.status === 400) {
                this.handleASPErrors(error.response.data.errors)
            }
            if (onError) onError(error)
        }
    }

    /**
     * A simplified version of {@link #JSONRequest} for get requests. can be awaited
     * @param   {String   } path      The path of the request. e.g. '/Items'
     * @param   {Function?} onSuccess A callback called if the request was successful. This is optional.
     * @param   {Function?} onError   A callback called if the request failed.         This is optional.
     * @returns {Promise<Object>} a promise with the json content
     */
    JSONGet (path, onSuccess, onError) {
        return this.JSONRequest('GET', path, {}, onSuccess, onError)
    }

    /**
     * Get patients.
     *
     * @param {Function} onSuccess - callback function.
     * @param {Function} onError - error callback function.
     * @param {FormData} patienformDatatNumber - number of patient
     * @version 1.0.0
     */
    async postInsole (formData, onSuccess, onError) {
        return this.JSONRequest('POST', '/Unity', formData, onSuccess, onError)
    }

    /**
     * Get patients.
     *
     * @param {Function} onSuccess - callback function.
     * @param {Function} onError - error callback function.
     * @param {Number} patientNumber - number of patient
     * @version 1.0.0
     */
    patients (patientNumber, onSuccess, onError) {
        return this.JSONGet(`/Patient/${patientNumber}`, onSuccess, onError)
    }

    /**
     * Get therapist.
     *
     * @param {Function} onSuccess - callback function.
     * @param {Function} onError - error callback function.
     * @version 1.0.0
     */
    therapists (onSuccess, onFailure) {
        return this.JSONGet('/Therapist', onSuccess, onFailure)
    }

    /**
     *
     * @param {*} callback -
     * @param {*} errorCallback -
     */
    location (callback, errorCallback) {
        return this.JSONGet('/Location', callback, errorCallback)
    }

    /**
     * Places a new order. can be awaited
     * @param order the data of the order to place
     * @param onSuccess
     * @param onError
     * @returns {Promise<Object>}
     */
    placeOrder (order, onSuccess, onError) {
        if (order.orderType === 'fits' || order.orderType === 'fitsCustom') {
            return this.JSONRequest('POST', '/order/fits', order, onSuccess, onError)
        } else {
            return this.JSONRequest('POST', '/order/insole', order, onSuccess, onError)
        }
    }

    /**
     *
     * @param {*} obj
     * @returns
     */
    recursiveStringify (obj) {
        if (obj && typeof obj === 'object' && !moment.isMoment(obj)) {
            const result = Array.isArray(obj) ? [] : {}
            for (const key in obj) {
                result[key] = this.recursiveStringify(obj[key])
            }
            return result
        }

        if (moment.isMoment(obj)) {
            return obj.toISOString
        } else {
            return obj
        }
    }

    /**
     * Places a new order. can be awaited
     * @param order the data of the order to place
     * @param onSuccess
     * @param onError
     * @returns {Promise<Object>}
     */
    placeOrderBootform (order, onSuccess, onError) {
        const processedObject = this.recursiveStringify(order)
        return this.JSONRequest('POST', '/order/bootForm', processedObject, onSuccess, onError)
    }

    /**
     * Gets the orders from the server. can be awaited
     * @param {Object} patientID the id of the patient for the order to get
     * @param {Function?} onSuccess a callback for success
     * @param {Function?} onError   a callback for errors
     * @returns {Promise<Object>} response
     */
    getOrder (patientID, orderType, onSuccess, onError) {
        return this.JSONGet(`/order?number=${patientID}&orderType=${orderType}`, onSuccess, onError)
    }

    /**
     * Update the backend URL
     *
     * @param {String} backend - the selected backend url
     */
    setBackend (backend) {
        this.backendUrl = backend
    }

    /**
     * Set the sharepoint token
     *
     * @param {String} token - the received token.
     */
    setSharepointInformation (microsoftResponse, queryParameters) {
        const response = JSON.parse(microsoftResponse)

        const patientNr = queryParameters.get('patient')
        const patientNumber = patientNr === null ? '' : `&patient=${patientNr}`

        if (response.error_description === 'linked token is expired' && queryParameters.get('redirected') === null) {
            const redirectURL = encodeURIComponent(`https://${window.location.host}${window.location.pathname}?redirected=true${patientNumber}`)
            window.location.replace(`${response['account-link-url']}&redirect_uri=${redirectURL}`)
            return
        }

        this.sharepointToken = response.access_token
        this.accountLinkUrl = `${response['account-link-url']}&redirect_uri=https://${window.location.host}`

        if (response.expires_in < 2400 && queryParameters.get('redirected') === null) {
            const redirectURL = encodeURIComponent(`https://${window.location.host}${window.location.pathname}?redirected=true${patientNumber}`)
            window.location.replace(`${response['account-link-url']}&redirect_uri=${redirectURL}`)
        }
    }

    /**
     *
     * @param {*} rhinoJSON
     */
    async uploadToSharepoint (rhinoJSON, fileName, onSuccess, onError) {
        const axios = require('axios')
        const config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: `https://graph.microsoft.com/v1.0/sites/voetencentrumwender590.sharepoint.com,5db3cbfd-afb9-4e2c-bebf-bf20b096ff68,27b33d2a-8432-41a2-926d-18a075b384c9/drives/b!_cuzXbmvLE6-v78gsJb_aCo9sycyhKJBkm0YoHWzhMkH9h1t788xSYzPfgPpKPWP/root:/Centraal modelleren (project)/ExportsWebapplicatieSlimModelleren/${FOLDER_LOCATION}/${fileName}:/content`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.sharepointToken
            },
            data: rhinoJSON
        }

        axios.request(config)
            .then((response) => {
                onSuccess(response)
            })
            .catch((error) => {
                onError(error)
            })
    }

    /**
     *
     * @param {*} pdf
     * @param {*} orderType
     * @param {*} patient
     * @param {*} fileName
     * @param {*} onSuccess
     */
    async postPDFPodoIT (pdf, orderType, patient, fileName) {
        const axios = require('axios')
        const config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: `${REACT_APP_API_URL}/order/pdf?orderType=${orderType}&id=${patient.patientData.id}&filename=${fileName}`,
            headers: {
                'Content-Type': 'application/pdf',
                Token: UserService.getToken(),
                Backend: this.backendUrl
            },
            data: pdf
        }

        await axios.request(config)
            .then(async () => { })
            .catch((error) => {
                console.error(error)
            })
    }

    /**
     *
     */
    async retrievePDF (fullstate, sendToSharepoint, onSuccess, orderType, patient) {
        const today = moment().format('DD-MM-YYYY')
        const fileName = `${patient.patientData.number.replace(' ', '')}_${orderType}_${today}.pdf`
        const data = {
            options: {
                format: 'a4',
                landscape: false,
                margin: { top: '4', right: '4', bottom: '4', left: '4' },
                scale: 1
            },
            html: jsonToHtml(fullstate).outerHTML,
            fileName,
            patientID: patient.patientData.id
        }
        const requestOptions = {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Token: UserService.getToken(),
                Backend: this.backendUrl
            },
            redirect: 'follow',
            body: JSON.stringify(data)
        }
        let blob
        fetch(`${REACT_APP_API_URL}/PDF`, requestOptions)
            .then(async (response) => {
                console.log(response)

                blob = await response.blob()
                console.log(response)
                const newBlob = new Blob([blob])
                this.postPDFPodoIT(newBlob, orderType, patient, fileName)

                // download if its not supposed to be sent to sharepoint
                if (!sendToSharepoint) {
                    const link = document.createElement('a')
                    // create a blobURI pointing to our Blob
                    link.href = URL.createObjectURL(blob)
                    link.download = fileName
                    // some browser needs the anchor to be in the doc
                    document.body.append(link)
                    link.click()
                    link.remove()
                    // in case the Blob uses a lot of memory
                    setTimeout(() => URL.revokeObjectURL(link.href), 7000)
                    onSuccess()
                    return
                }

                const axios = require('axios')
                const config = {
                    method: 'put',
                    maxBodyLength: Infinity,
                    url: `https://graph.microsoft.com/v1.0/sites/${this.wittepoelSite}/drives/${this.wittepoelDriveID}/root:/Bestellingen/${fileName}:/content`,
                    headers: {
                        'Content-Type': 'application/pdf',
                        Authorization: `Bearer ${this.sharepointToken}`
                    },
                    data: newBlob
                }

                await axios.request(config)
                    .then(async (response) => {
                        onSuccess()
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            })
            .catch((error) => console.error(error))
    }
}

/**
 *
 * @param {*} json
 * @returns
 */
function jsonToHtml (json) {
    if (!moment.isMoment(json) && typeof json === 'object' && json !== null) {
        if (Array.isArray(json)) {
            const ul = document.createElement('ul')
            ul.style.maxWidth = '100%'
            ul.style.maxHeight = '100%'
            ul.style.overflow = 'hidden'
            json.forEach(item => {
                const li = document.createElement('li')
                li.appendChild(jsonToHtml(item))
                ul.appendChild(li)
            })
            return ul
        } else {
            const table = document.createElement('table')
            table.style.borderCollapse = 'collapse'
            table.style.maxWidth = '100%'
            table.style.maxHeight = '100%'
            table.style.overflow = 'hidden'
            table.style.pageBreakInside = 'avoid'

            Object.keys(json).forEach(key => {
                if (key.includes('Accordion') || key.includes('Errors')) return
                if (exceptions.includes(key)) return
                if (json[key] === '' || json[key] === '-' || json[key] === false || json[key] === null) return

                if (key.includes('patientData')) {
                    columnBasedHTML(table, key, json[key])
                    return
                }

                // let containsLeft = false
                // Object.keys(json[key]).forEach(subKey => {
                //     if (subKey === 'left') containsLeft = true
                // })

                // if (containsLeft) {
                //     leftRightTableHTML(table, key, json[key])
                //     return
                // }


                if (["customerCosts", "insuranceCosts", "institutionCosts"].includes(key)) {
                    // Format float into price
                    let price = parseFloat(json[key]).toFixed(2).replace(".", ",")
                    customHTML(table, key, `€ ${price}`)
                    return
                }

                const tr = document.createElement('tr')

                const th = document.createElement('th')
                th.textContent = translation[key] || key // Use translation if available
                th.style.border = '1px solid black'
                th.style.padding = '5px'
                tr.appendChild(th)

                const td = document.createElement('td')
                td.appendChild(jsonToHtml(json[key]))
                td.style.border = '1px solid black'
                td.style.padding = '5px'
                tr.appendChild(td)

                table.appendChild(tr)
            })
            return table
        }
    } else {
        const span = document.createElement('span')
        if (json === true) {
            span.textContent = 'Ja'
        } else {
            if (moment.isMoment(json)) {
                span.textContent = moment(json).format('DD-MM-YYYY')
            } else {
                span.textContent = json
            }
        }
        return span
    }
}

/**
 *
 */
function columnBasedHTML (table, key, json) {
    const tr = document.createElement('tr')
    const th = document.createElement('th')
    th.textContent = translation[key] || key // Use translation if available
    th.style.border = '1px solid black'
    th.style.padding = '5px'
    tr.appendChild(th)

    const td = document.createElement('td')
    td.style.border = '1px solid black'
    td.style.padding = '5px'

    const subTable = document.createElement('table')
    subTable.style.borderCollapse = 'collapse'
    subTable.style.width = '100%'
    const thead = document.createElement('thead')
    const tbody = document.createElement('tbody')
    const subTrH = document.createElement('tr')
    const subTrB = document.createElement('tr')
    Object.keys(json).forEach(key => {
        if (exceptions.includes(key)) return // Skip keys in the exceptions list
        const th = document.createElement('th')
        th.textContent = translation[key] || key // Use translation if available
        th.style.border = '1px solid black'
        th.style.padding = '5px'

        const subTd = document.createElement('td')
        subTd.style.border = '1px solid black'
        subTd.style.padding = '5px'

        const span = document.createElement('span')

        // custom code receive and fitting location
        if (key === 'receiveLocation' || key === 'fittingLocation') {
            span.textContent = json[key].title
            subTd.appendChild(span)
        } else if (key === 'therapist') {
            span.textContent = json[key].name
            subTd.appendChild(span)
        } else if (json[key] === true) {
            span.textContent = 'Ja'
            subTd.appendChild(span)
        } else if (moment.isMoment(json[key])) {
            span.textContent = moment(json[key]).format('DD-MM-YYYY')
            subTd.appendChild(span)
        } else {
            span.textContent = json[key]
            subTd.appendChild(span)
        }
        subTrH.appendChild(th)
        subTrB.appendChild(subTd)
    })
    thead.appendChild(subTrH)
    tbody.appendChild(subTrB)
    subTable.appendChild(thead)
    subTable.appendChild(tbody)
    td.appendChild(subTable)
    tr.appendChild(td)
    table.appendChild(tr)
}

/**
 *
 * @param {*} table
 * @param {*} key
 * @param {*} json
 */
function leftRightTableHTML (table, key, json) {
    const tr = document.createElement('tr')
    const th = document.createElement('th')
    th.textContent = translation[key] || key // Use translation if available
    th.style.border = '1px solid black'
    th.style.padding = '5px'
    tr.appendChild(th)

    const td = document.createElement('td')
    td.style.border = '1px solid black'
    td.style.padding = '5px'

    const mainTable = document.createElement('table')
    Object.keys(json).forEach(key => {
        if (key === 'left' || key === 'right') {
            const subTable = document.createElement('table')
            subTable.style.borderCollapse = 'collapse'
            subTable.style.width = '100%'

            const thead = document.createElement('thead')
            const trow = document.createElement('tr')
            const leftR = document.createElement('th')
            const rightR = document.createElement('th')

            leftR.textContent = 'Links'
            leftR.style.border = '1px solid black'
            leftR.style.padding = '5px'

            rightR.textContent = 'Rechts'
            rightR.style.border = '1px solid black'
            rightR.style.padding = '5px'

            trow.appendChild(leftR)
            trow.appendChild(rightR)
            thead.appendChild(trow)
            subTable.appendChild(thead)

            const tbody = document.createElement('tbody')
            const tr = document.createElement('tr')

            const tdL = document.createElement('td')
            const tdR = document.createElement('td')

            const tableL = document.createElement('table')
            const tableR = document.createElement('table')

            tableL.style.borderCollapse = 'collapse'
            tableL.style.maxWidth = '100%'
            tableL.style.maxHeight = '100%'
            tableL.style.overflow = 'hidden'
            tableL.style.breakInside = 'avoid'
            tableR.style.borderCollapse = 'collapse'
            tableR.style.maxWidth = '100%'
            tableR.style.maxHeight = '100%'
            tableR.style.overflow = 'hidden'
            tableR.style.breakInside = 'avoid'

            const subTbodyL = document.createElement('tbody')
            const subTbodyR = document.createElement('tbody')

            // do left
            loopLRObjects(subTbodyL, json.left)
            // do right
            loopLRObjects(subTbodyR, json.right)

            tableL.appendChild(subTbodyL)
            tableR.appendChild(subTbodyR)
            tdL.appendChild(tableL)
            tdR.appendChild(tableR)
            tr.appendChild(tdL)
            tr.appendChild(tdR)
            tbody.appendChild(tr)
            mainTable.appendChild(tbody)
        } else if (key !== 'left' && key !== 'right') {
            const tr = document.createElement('tr')

            const th = document.createElement('th')
            th.textContent = translation[key] || key // Use translation if available
            th.style.border = '1px solid black'
            th.style.padding = '5px'
            tr.appendChild(th)

            const td = document.createElement('td')
            td.appendChild(jsonToHtml(json[key]))
            td.style.border = '1px solid black'
            td.style.padding = '5px'
            tr.appendChild(td)

            mainTable.appendChild(tr)
        }
    })

    table.appendChild(tr)
}

/**
 *
 * @param {*} table
 * @param {*} json
 */
function loopLRObjects (table, json) {
    Object.keys(json).forEach(key => {
        const tr = document.createElement('tr')

        const th = document.createElement('th')
        th.textContent = translation[key] || key // Use translation if available
        th.style.border = '1px solid black'
        th.style.padding = '5px'
        tr.appendChild(th)

        const td = document.createElement('td')
        td.appendChild(jsonToHtml(json[key]))
        td.style.border = '1px solid black'
        td.style.padding = '5px'
        tr.appendChild(td)

        table.appendChild(tr)
    })
}

const exceptions = [
    'initials',
    'birthdate',
    'id',
    'entryDate',
    'activeLasts'
]

// eslint-disable-next-line new-cap
export default (new API())
